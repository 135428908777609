import { saveAs } from 'file-saver';
import { Document, Packer, AlignmentType } from 'docx';
import { styles, numbering } from '../wordDoc.styles';
import {
  clientDeclarations,
  thankYouIntro,
  guaranteesAndLimitationsIncomeProtection,
} from './data/insuranceDocument';
import { incomeProtectionRecommendedProducts } from './data/recommendedProduct';
import {
  royalLondonBenefits,
  termProtectionAvivaBenefits,
  termProtectionNewIrelandBenefits,
  termProtectionZurichLifeBenefits,
} from './data/companyBenefits';
import {
  boldTextRun,
  paragraphWithBullet,
  paragraphWithTextRun,
  textRun,
  generateBulletPoints,
} from '../wordDoc.utils';
import {
  incomeProtectionExtraNeedsObjectivesBullets,
  incomeProtectionExtraNeedsObjectivesParagraph,
  incomeProtectionNeedsAndObjectives,
} from './data/needsAndObjectives';
import { removeSpace, removeSpecialCharacters } from '../../../utils/utils';
import { addedBenefits } from './data/companyAddedBenefits';
import { HastingsHeader } from './components/Header/Header';
import { HastingsFooter } from '../components/Footer/Footer';
import { signature } from './components/Signature/Signature';
import { PersonalCircumstances } from './components/PersonalCirumstances/PersonalCircumstances';
import { ClientAdvisor } from './components/ClientAdvisor/ClientAdvisor';
import { HeaderMessage } from '../components/HeaderMessage/HeaderMessage';
import { DeclarationsLiabilities } from './components/DeclarationsLiabilities/DeclarationsLiabilities';
import { OnBehalfOfSignature } from './components/OnBehalfOfSignature/OnBehalfOfSignature';

export const generateIncomeProtection = ({
  financialAdvisor,
  client1FirstName,
  client1LastName,
  client2FirstName,
  type: protection,
  deferredPeriod,
  client2LastName,
  numberOfDependants,
  client1Smoker,
  client2Smoker,
  companyName,
  premiumAmount,
  client1MaritalStatus,
  client2MaritalStatus,
  basis,
  hastingsCompany,
  NRA,
  benefitAmount,
  clientCompanyName,
}) => {
  const clientFullNames = client2LastName
    ? `${client1FirstName} ${client1LastName} & ${client2FirstName} ${client2LastName}`
    : `${client1FirstName} ${client1LastName}`;

  const client1FullName = `${client1FirstName} ${client1LastName}`;
  const client2FullName =
    client2FirstName && `${client2FirstName} ${client1LastName}`;

  const greetClients = client2FirstName
    ? `${client1FirstName} & ${client2FirstName}`
    : client1FirstName;

  const isTwoClients = !!client2FirstName;

  const generateExtraNeedsObjectives = () => [
    paragraphWithTextRun({
      children: [
        textRun({
          text: incomeProtectionExtraNeedsObjectivesParagraph,
          lineBreak: 1,
        }),
      ],
      spacingAfter: 0.1,
    }),
    paragraphWithBullet({
      text: incomeProtectionExtraNeedsObjectivesBullets[0].text,
    }),
    paragraphWithBullet({
      text: incomeProtectionExtraNeedsObjectivesBullets[1].text,
    }),
    paragraphWithBullet({
      text: incomeProtectionExtraNeedsObjectivesBullets[2].text,
    }),
  ];

  const companyBenefits = {
    IncomeProtection: {
      AvivaLifePensions: termProtectionAvivaBenefits,
      NewIreland: termProtectionNewIrelandBenefits,
      IrishLife: termProtectionZurichLifeBenefits,
      ZurichLife: termProtectionZurichLifeBenefits,
      RoyalLondon: royalLondonBenefits(basis),
    },
  };

  const getCompanyBenefits = ({ protectionType, company }) => {
    const companyWithNoSpecialCharacters = removeSpecialCharacters(
      removeSpace(company)
    );
    const filteredBenefits = companyBenefits[removeSpace(protectionType)];
    return filteredBenefits[companyWithNoSpecialCharacters];
  };

  const needsAndObjectives = {
    IncomeProtection: incomeProtectionNeedsAndObjectives({
      benefitAmount,
      NRA,
      deferredPeriod,
    }),
  };

  const guaranteedMortgageProtection = {
    IncomeProtection: `${basis} Income Protection.`,
  };

  const recommendedProduct = {
    IncomeProtection: incomeProtectionRecommendedProducts({
      protection,
      premiumAmount,
      benefitAmount,
      NRA,
    }),
  };

  const companyAddedBenefits = addedBenefits({
    insuranceType: '',
    basis,
    protection,
    companyName,
  });

  const doc = new Document({
    creator: 'Hastings',
    title: `${clientFullNames} statement of suitability.`,
    description: `Recommendation: ${companyName} `,
    styles,
    numbering,
    sections: [
      {
        headers: HastingsHeader,
        footers: HastingsFooter({ hastingsCompany }),
        children: [
          HeaderMessage,
          paragraphWithTextRun({
            children: [boldTextRun({ text: '' })],
          }),
          ClientAdvisor({
            mortgageProtectionType:
              guaranteedMortgageProtection[removeSpace(protection)],
            financialAdvisor,
            clientFullNames,
            clientCompanyName,
          }),
          paragraphWithTextRun({
            children: [
              textRun({
                text: `Dear ${greetClients}, `,
                lineBreak: 1,
              }),
            ],
          }),
          paragraphWithTextRun({
            children: [textRun({ text: thankYouIntro, lineBreak: 1 })],
          }),
          paragraphWithTextRun({
            children: [
              boldTextRun({
                text: 'Your Personal Circumstances: ',
                lineBreak: 1,
              }),
            ],
            spacingAfter: 0.1,
          }),
          ...PersonalCircumstances({
            clientFirstName: client1FirstName,
            smoker: client1Smoker,
            numberOfDependants,
            maritalStatus: client1MaritalStatus,
            isTwoClients,
            protection,
          }),
          ...PersonalCircumstances({
            clientFirstName: client2FirstName,
            smoker: client2Smoker,
            numberOfDependants,
            maritalStatus: client2MaritalStatus,
            isTwoClients,
            lineBreak: 1,
            protection,
          }),
          paragraphWithTextRun({
            children: [
              boldTextRun({
                text: 'Your Needs & Objectives: ',
                lineBreak: 1,
              }),
            ],
            spacingAfter: 0.1,
          }),
          ...generateBulletPoints({
            bulletArray: needsAndObjectives[removeSpace(protection)],
          }),
          ...generateExtraNeedsObjectives(),
          paragraphWithTextRun({
            children: [
              boldTextRun({
                text: 'Recommended Product: ',
                alignment: AlignmentType,
                lineBreak: 1,
              }),
              textRun({
                text: guaranteedMortgageProtection[removeSpace(protection)],
              }),
            ],
            spacingAfter: 0.1,
          }),
          ...generateBulletPoints({
            bulletArray: recommendedProduct[removeSpace(protection)],
          }),
          paragraphWithTextRun({
            children: [
              textRun({
                text:
                  'Prior to policy purchase, you may be able to add the following benefits to your protection plan at an\n' +
                  'additional cost:',
                lineBreak: 1,
              }),
            ],
            spacingAfter: 0.1,
          }),
          ...generateBulletPoints({
            bulletArray:
              companyAddedBenefits[
                removeSpecialCharacters(removeSpace(companyName))
              ],
          }),
          paragraphWithTextRun({
            children: [
              textRun({
                text: 'Please see policy documentation for further details on the benefits and options available.',
                lineBreak: 1,
              }),
            ],
          }),
          paragraphWithTextRun({
            children: [
              boldTextRun({
                text: 'Our Recommended Product Provider: ',
                lineBreak: 2,
              }),
            ],
            spacingAfter: 0.1,
          }),
          paragraphWithTextRun({
            children: [
              textRun({
                text: `Based on the above information, we recommend that you effect the policy with ${companyName}. The reasons why I recommend this product are as follows:`,
                lineBreak: 1,
              }),
            ],
          }),
          paragraphWithTextRun({
            children: [
              boldTextRun({
                text: '',
              }),
            ],
          }),
          ...generateBulletPoints({
            bulletArray: getCompanyBenefits({
              protectionType: protection,
              company: companyName,
            }),
          }),
          paragraphWithTextRun({
            children: [
              boldTextRun({
                text: 'Guarantees and Limitations: ',
                lineBreak: 1,
              }),
            ],
            spacingAfter: 0.1,
          }),
          ...generateBulletPoints({
            bulletArray: guaranteesAndLimitationsIncomeProtection({
              insuranceCompanyName: companyName,
            }),
          }),
          ...DeclarationsLiabilities(),
          signature({
            textUnderSigned: financialAdvisor,
            secondLine: `On behalf of ${hastingsCompany}`,
          }),
          paragraphWithTextRun({
            children: [
              boldTextRun({
                text: 'Declaration to be completed by client',
                lineBreak: 1,
              }),
            ],
            spacingAfter: 0.1,
            spacingBefore: 0.2,
          }),
          ...generateBulletPoints({
            bulletArray: clientDeclarations({
              insuranceCompanyName: companyName,
              client2FirstName,
            }),
          }),
          paragraphWithTextRun({
            children: [],
            spacingAfter: 0.3,
          }),
          signature({
            textUnderSigned: client1FullName,
          }),
          OnBehalfOfSignature({ textUnderSigned: clientCompanyName }),
        ],
      },
    ],
  });

  const documentName = client2FirstName
    ? `${client1FullName} ${client2FullName} Statement of Suitability.docx`
    : `${client1FullName} Statement of Suitability.docx`;

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, documentName);
  });
};
