import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { populateArray } from './tableRows';

export const ProtectionSinglePremiumContent = ({ protectionResult }) => {
  if (!protectionResult) {
    return <p>No Protection Commission Structure Available</p>;
  }

  const tableRows = populateArray({ protectionResult });
  return (
    <Table>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={row.key}>
            <TableCell style={{ width: 160 }} align="left">
              {row.label}
            </TableCell>
            <TableCell style={{ width: 160 }} align="left">
              {row.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

ProtectionSinglePremiumContent.propTypes = {
  protectionResult: PropTypes.number,
};
