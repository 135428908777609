import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { populateArray } from './tableRows';

export const SavingsRegularPremiumContent = ({ regularPremiumResult }) => {
  if (!regularPremiumResult) {
    return <p>No Savings & Investments Commission Structure Available</p>;
  }

  const tableRows = populateArray({ regularPremiumResult });
  return (
    <Table>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={row.key}>
            <TableCell style={{ width: 160 }} align="left">
              {row.label}
            </TableCell>
            <TableCell style={{ width: 160 }} align="left">
              {row.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

SavingsRegularPremiumContent.propTypes = {
  regularPremiumResult: PropTypes.object,
};
