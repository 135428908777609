export const getKeyByValue = (object, value) => {
  Object.keys(object).find((key) => object[key] === value);
};

export const removeSpace = (string) => string && string.replace(/\s/g, '');

export const removeSpecialCharacters = (string) =>
  string && string.replace(/[^a-zA-Z ]/g, '');

export const convertStringCurrencyToNumber = (string) =>
  string && Number(string.replace(/[^0-9.-]+/g, ''));

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  const age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    return age - 1;
  }
  return age;
};

export const getDateToday = () => {
  const date = new Date();
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${day} ${month} ${year}`;
};
