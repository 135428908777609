import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../FormLabel/FormLabel';
import { SelectInput } from './SelectInput/SelectInput';

export const SelectMenu = ({
  formControl,
  itemArray,
  selectMenuName,
  selectFieldName = '',
  errors = {},
  playTransition = true,
  isRequired = true,
  defaultValue,
}) => (
  <>
    <FormLabel label={selectFieldName} playTransition={playTransition} />
    <SelectInput
      isRequired={isRequired}
      errors={errors}
      formControl={formControl}
      itemArray={itemArray}
      selectFieldName={selectFieldName}
      selectMenuName={selectMenuName}
      defaultValue={defaultValue}
      playTransition={playTransition}
    />
  </>
);

SelectMenu.propTypes = {
  formControl: PropTypes.object,
  itemArray: PropTypes.array,
  selectMenuName: PropTypes.string,
  selectFieldName: PropTypes.string,
  defaultValue: PropTypes.string,
  errors: PropTypes.object,
  isRequired: PropTypes.bool,
  playTransition: PropTypes.bool,
};
