import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from '../../config/UserPool';
import AccountContext from './AccountContext';
import { SEVERITY_TYPES } from '../../constants/toast';

export const AccountProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const { addToast } = useToasts();

  const history = useHistory();
  const getSession = async () =>
    new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((res, rej) => {
              user.getUserAttributes((error, attrs) => {
                if (error) {
                  rej(error);
                } else {
                  const results = {};

                  // eslint-disable-next-line no-restricted-syntax
                  for (const attribute of attrs) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  res(results);
                }
              });
            });

            resolve({ user, ...session, ...attributes });
            setIsAuthenticated(true);
          }
        });
      } else {
        reject();
      }
    });

  const authenticate = async ({ Username, Password, NewPassword }) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setIsAuthenticated(true);
          resolve(data);
          addToast('Login successful', {
            appearance: SEVERITY_TYPES.success,
            autoDismiss: true,
          });
          history.push('/Dashboard');
        },
        onFailure: (err) => {
          addToast('Login failed', {
            appearance: SEVERITY_TYPES.error,
            autoDismiss: true,
          });
          setIsAuthenticated(false);
          reject(err);
        },
        newPasswordRequired: (data) => {
          if (!NewPassword) {
            resolve(data);
            addToast('Password change required.', {
              appearance: SEVERITY_TYPES.info,
              autoDismiss: true,
            });
            history.push('/ChangePassword');
          }
          user.completeNewPasswordChallenge(
            NewPassword,
            {},
            {
              onSuccess: () => {
                addToast('Password Changed successfully', {
                  appearance: SEVERITY_TYPES.success,
                  autoDismiss: true,
                });
                setIsAuthenticated(true);
                history.push('/Dashboard');
              },
              onFailure: () => {
                addToast('Failed to change password, please try again.', {
                  appearance: SEVERITY_TYPES.success,
                  autoDismiss: true,
                });
                setIsAuthenticated(false);
              },
            }
          );
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      addToast('Logout successful', {
        appearance: SEVERITY_TYPES.success,
        autoDismiss: true,
      });
      setIsAuthenticated(false);
      history.push('/Login');
    }
  };

  useEffect(() => {
    getSession()
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch(() => setIsAuthenticated(false));
  }, []);

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        isAuthenticated,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node,
};
