import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { SelectMenu } from '../../../components/SelectMenu/SelectMenu';
import { PENSIONS_TYPES } from './Pensions.constant';
import { EXECUTIVE_PENSION, TYPE } from '../../../constants/form';
import { ExecutivePension } from './ExectutivePension/ExecutivePension';
import { listStatementOfSuitabilityPensions } from '../../../graphql/queries';
import { GraphQLClient } from '../../../graphql/GraphQLClient';

export const Pensions = ({
  formControl,
  errors,
  watch,
  setValue,
  updateDynamicStatement,
}) => {
  const currentPension = watch(TYPE);

  useEffect(() => {
    GraphQLClient.query({
      query: gql(listStatementOfSuitabilityPensions),
      variables: { limit: 500 },
    })
      .then((response) => {
        updateDynamicStatement(
          response.data.listStatementOfSuitabilityPensions.items
        );
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }, []);

  return (
    <>
      <SelectMenu
        formControl={formControl}
        selectMenuName={TYPE}
        itemArray={PENSIONS_TYPES}
        selectFieldName="Pension Type"
        errors={errors}
      />
      {currentPension === EXECUTIVE_PENSION && (
        <ExecutivePension
          formControl={formControl}
          errors={errors}
          setValue={setValue}
          currentPension={currentPension}
        />
      )}
    </>
  );
};

Pensions.propTypes = {
  watch: PropTypes.func,
  errors: PropTypes.object,
  formControl: PropTypes.func,
  updateDynamicStatement: PropTypes.func,
  setValue: PropTypes.func,
};
