import React from 'react';
import PropTypes from 'prop-types';
import { HASTINGS_COMPANY, PRODUCT_TYPE } from '../../../../data/LifeInsurance';
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu';
import { UserInput } from '../../../../components/UserInput/UserInput';

export const InitialSelection = ({ formControl, errors, watch }) => {
  const hastingsCompany = watch('hastingsCompany');
  return (
    <>
      <UserInput
        inputName="financialAdvisor"
        formControl={formControl}
        placeholder="Financial advisor's name"
        autoComplete="on"
      />
      <SelectMenu
        errors={errors}
        formControl={formControl}
        selectMenuName="hastingsCompany"
        itemArray={HASTINGS_COMPANY}
        defaultValue={hastingsCompany || ''}
        selectFieldName="Company"
        playTransition
      />
      <SelectMenu
        formControl={formControl}
        selectMenuName="productType"
        itemArray={PRODUCT_TYPE}
        selectFieldName="Product"
        errors={errors}
        playTransition
      />
    </>
  );
};

InitialSelection.propTypes = {
  watch: PropTypes.func,
  formControl: PropTypes.object,
  errors: PropTypes.object,
};
