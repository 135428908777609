import {
  boldTextRun,
  paragraphWithTextRun,
  textRun,
} from '../../wordDoc.utils';
import { signature } from '../../Insurance/components/Signature/Signature';
import { warnings } from './warnings';

export const page4 = ({ clientName, clientCompanyName }) => [
  paragraphWithTextRun({
    children: [
      textRun({
        text: 'To be completed by the client:',
        lineBreak: 3,
      }),
    ],
    spacingAfter: 0.1,
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Declaration to be completed by client:',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  signature({ textUnderSigned: `${clientName} - Employee` }),
  signature({ textUnderSigned: `For and on behalf of ${clientCompanyName}` }),
  paragraphWithTextRun({
    children: [
      textRun({
        text: '',
        lineBreak: 5,
      }),
    ],
    spacingAfter: 0.1,
  }),
  warnings(),
];
