import React from 'react';
import PropTypes from 'prop-types';
import { Slider, Zoom } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { FormLabel } from '../FormLabel/FormLabel';

export const NumberSlider = ({
  formControl,
  sliderName,
  step = 1,
  min = 0,
  max = 6,
  showMarks = true,
  defaultValue = 3,
  onChange = () => {},
  label = '',
  playTransition = true,
}) => {
  if (!playTransition) {
    return (
      <>
        {label && <FormLabel label={label} />}
        <Controller
          name={sliderName}
          control={formControl}
          defaultValue={defaultValue}
          render={(props) => (
            <div style={{ width: '100%' }}>
              <Slider
                {...props}
                onChange={(_, value) => {
                  onChange(value);
                }}
                showMarks={showMarks}
                valueLabelDisplay="auto"
                max={max}
                defaultValue={defaultValue}
                min={min}
                step={step}
              />
            </div>
          )}
        />
      </>
    );
  }
  return (
    <>
      {label && <FormLabel label={label} />}
      <Controller
        name={sliderName}
        control={formControl}
        defaultValue={defaultValue}
        render={(props) => (
          <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
            <div style={{ width: '100%' }}>
              <Slider
                {...props}
                onChange={(_, value) => {
                  onChange(value);
                }}
                showMarks={showMarks}
                valueLabelDisplay="auto"
                max={max}
                defaultValue={defaultValue}
                min={min}
                step={step}
              />
            </div>
          </Zoom>
        )}
      />
    </>
  );
};

NumberSlider.propTypes = {
  formControl: PropTypes.object,
  sliderName: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  showMarks: PropTypes.bool,
  playTransition: PropTypes.bool,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
};
