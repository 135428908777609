import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectMenu } from '../../../components/SelectMenu/SelectMenu';
import { PROTECTION_TYPES } from '../../../data/LifeInsurance';
import { TermProtectionInsurance } from './TermProtectionInsurance/TermProtectionInsurance';
import { IncomeProtectionInsurance } from './IncomeProtectionInsurance/IncomeProtectionInsurance';
import { WholeOfLifeInsurance } from './WholeOfLifeInsurance/WholeOfLifeInsurance';
import {
  DUAL,
  INCOME_PROTECTION,
  JOINT,
  TYPE,
  TERM_PROTECTION,
  WHOLE_OF_LIFE,
} from '../../../constants/form';

export const LifeInsurance = ({ setValue, watch, errors, formControl }) => {
  const basis = watch('basis');
  const protection = watch(TYPE);

  const determineHowManyClientsToShow = () => {
    if (basis) {
      return basis.includes(DUAL) || basis.includes(JOINT);
    }
    return null;
  };

  const showTwoClients = determineHowManyClientsToShow();

  useEffect(() => {
    if (!showTwoClients) {
      setValue('client2FirstName', '');
      setValue('client2LastName', '');
      setValue('client2MaritalStatus', '');
    }
  }, [showTwoClients, setValue]);

  return (
    <>
      <SelectMenu
        formControl={formControl}
        selectMenuName={TYPE}
        itemArray={PROTECTION_TYPES}
        selectFieldName="Protection Type"
        errors={errors}
        playTransition
      />
      {protection === TERM_PROTECTION && (
        <TermProtectionInsurance
          formControl={formControl}
          watch={watch}
          errors={errors}
          setValue={setValue}
        />
      )}
      {protection === WHOLE_OF_LIFE && (
        <WholeOfLifeInsurance
          errors={errors}
          watch={watch}
          formControl={formControl}
          setValue={setValue}
        />
      )}
      {protection === INCOME_PROTECTION && (
        <IncomeProtectionInsurance
          setValue={setValue}
          formControl={formControl}
          watch={watch}
          errors={errors}
        />
      )}
    </>
  );
};

LifeInsurance.propTypes = {
  setValue: PropTypes.object,
  watch: PropTypes.func,
  errors: PropTypes.object,
  formControl: PropTypes.func,
};
