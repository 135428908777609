import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { Container, Grid, Paper } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { UserInput } from '../../components/UserInput/UserInput';
import Theme from '../../constants/theme';
import useAccount from '../../providers/AccountProvider/useAccount';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import hastingsToolkitLogo from '../../assets/images/HastingsToolkit.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: Theme.spacing(5),
      paddingBottom: Theme.spacing(5),
    },
    paper: {
      padding: Theme.spacing(5),
      justifyContent: 'center',
    },
    button: {
      marginTop: Theme.spacing(3),
      width: '100%',
    },
    error: {
      color: Theme.palette.error.main,
      fontSize: '14px',
    },
  })
);

export const Login = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { control, handleSubmit } = useForm({});
  const history = useHistory();
  const classes = useStyles();

  const { authenticate } = useAccount();

  const onSubmit = (data) => {
    setLoading(true);
    authenticate({ Username: data.username, Password: data.password })
      .then(() => {
        history.push('/Dashboard');
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper variant="outlined" className={classes.paper} elevation={3}>
        <Grid alignItems="center" justifyContent="center" spacing={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.grid}
              style={{ gap: 15 }}
            >
              <img
                src={hastingsToolkitLogo}
                alt="hastingsToolkitLogo"
                width={150}
                className={classes.image}
                draggable={false}
              />
              <UserInput
                formControl={control}
                inputName="username"
                placeholder="Username"
                disabiled={loading}
                inputStyle={{ marginBottom: '16px' }}
              />
              <PasswordInput
                formControl={control}
                inputName="password"
                placeholder="Password *"
                type="password"
                disabled={loading}
              />
              {error && <span className={classes.error}>{error}</span>}
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress size={20} style={{ marginRight: 20 }} />
                )}
                Login
              </Button>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Container>
  );
};
