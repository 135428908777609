import { convertInchesToTwip, Header, ImageRun, Paragraph } from 'docx';
import { HastingsBase64 } from '../../../assets/HastingsBase64';

const headerImage = new ImageRun({
  data: HastingsBase64,
  transformation: {
    width: 170,
    height: 55,
  },
  floating: {
    horizontalPosition: {
      offset: convertInchesToTwip(3500),
    },
    verticalPosition: {
      offset: convertInchesToTwip(100),
    },
    margins: {
      top: 100,
      left: 1000,
      right: 100,
    },
  },
});

export const HastingsHeader = {
  default: new Header({
    children: [
      new Paragraph({
        children: [headerImage],
      }),
    ],
  }),
};
