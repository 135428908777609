import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Theme from '../../constants/theme';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      transform: 'translateY(0px)',
      transition: 'transform 500ms ease 0s',
      maxWidth: 345,
      '&:hover, &:focus': {
        transform: 'translateY(-10px)',
        transition: 'transform 500ms ease 0s',
        cursor: 'pointer',
      },
    },
    media: {
      height: 180,
    },
    link: {
      textDecoration: 'none',
      color: Theme.palette.primary.main,
    },
  })
);

export const LandingCard = ({ heading, description, link, image }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <Link to={link} className={classes.link}>
          <CardMedia
            className={classes.media}
            image={image}
            title={description}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {heading}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
};

LandingCard.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
};
