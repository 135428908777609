import {
  boldTextRun,
  generateBulletPoints,
  paragraphWithTextRun,
  textRun,
} from '../../wordDoc.utils';
import {
  GUARANTEES_LIMITATIONS,
  GUARANTEES_LIMITATIONS_SECTION,
  riskProfileMatchESG,
} from './data/executivePage3';
import { signature } from '../../Insurance/components/Signature/Signature';

export const page3 = ({
  financialAdvisor,
  dynamicStatements = [],
  ESG,
  riskProfileMatch,
}) => [
  ...generateBulletPoints({
    bulletArray: dynamicStatements,
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Guarantees and Limitations:',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...generateBulletPoints({
    bulletArray: GUARANTEES_LIMITATIONS,
  }),
  paragraphWithTextRun({
    children: [
      textRun({
        text: 'Please read the Guarantees and Limitations section.',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  paragraphWithTextRun({
    children: [
      textRun({
        text: GUARANTEES_LIMITATIONS_SECTION,
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.6,
  }),
  ...generateBulletPoints({
    bulletArray: riskProfileMatchESG({ ESG, riskProfileMatch }),
  }),
  signature({
    textUnderSigned: financialAdvisor,
    secondLine: 'Financial Advisor',
  }),
];
