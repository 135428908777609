import { HeaderMessage } from '../../components/HeaderMessage/HeaderMessage';
import {
  boldTextRun,
  generateBulletPoints,
  paragraphWithTextRun,
  textRun,
} from '../../wordDoc.utils';
import { pensionClientAdvisor } from '../components/PensionClientAdvisor/pensionClientAdvisor';
import {
  financialSituation,
  GREETING,
  needsAndObjectives,
  personalCircumstances,
} from './data/executivePage1';

export const page1 = ({
  financialAdvisor,
  clientFullName,
  retirementAge,
  clientCompanyName,
  age,
  clientFirstName,
  employeeContribution,
  employerContribution,
  salary,
}) => [
  HeaderMessage,
  paragraphWithTextRun({
    children: [boldTextRun({ text: '' })],
  }),
  pensionClientAdvisor({
    financialAdvisor,
    clientFullName,
    retirementAge,
    clientCompanyName,
    age,
  }),
  paragraphWithTextRun({
    children: [
      textRun({
        text: `Dear ${clientFirstName}, `,
        lineBreak: 1,
      }),
    ],
  }),
  paragraphWithTextRun({
    children: [textRun({ text: GREETING, lineBreak: 1 })],
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Your Personal Circumstances: ',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...generateBulletPoints({
    bulletArray: personalCircumstances({
      clientCompanyName,
      employeeContribution,
      employerContribution,
    }),
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Your Needs & Objectives: ',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...generateBulletPoints({
    bulletArray: needsAndObjectives,
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Your Financial Situation: ',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...generateBulletPoints({
    bulletArray: financialSituation({ salary, employeeContribution }),
  }),
];
