import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { ClientForm } from '../../../../components/ClientForm/ClientForm';
import { FormattedNumberInput } from '../../../../components/FormattedNumberInput/FormattedNumberInput';

import { UserInput } from '../../../../components/UserInput/UserInput';
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu';
import {
  AMC_FORM_NAME,
  EMPLOYEE_CONTRIBUTION_FORM_NAME,
  EMPLOYER_CONTRIBUTION_FORM_NAME,
  PENSION_COMPANIES,
  COMPANY_FORM_NAME,
  RISK_PROFILE_FORM_NAME,
  RISK_PROFILES,
  TRAIL_FORM_NAME,
  ALLOCATION_RATE_FORM_NAME,
} from '../Pensions.constant';
import { removeSpace } from '../../../../utils/utils';
import { NumberSlider } from '../../../../components/NumberSlider/NumberSlider';
import { TickBox } from '../../../../components/TickBox/TickBox';

export const ExecutivePension = ({
  formControl,
  errors,
  currentPension,
  setValue,
}) => {
  const onRetirementAgeChange = (value) => {
    setValue('retirementAge', value);
  };

  return (
    <>
      <SelectMenu
        formControl={formControl}
        selectMenuName={COMPANY_FORM_NAME}
        itemArray={PENSION_COMPANIES[removeSpace(currentPension)]}
        selectFieldName="Company Name"
        errors={errors}
      />
      <ClientForm
        formControl={formControl}
        errors={errors}
        showSalary
        showDOB
      />
      <Grid item xs={12} md={5}>
        <FormattedNumberInput
          defaultValue=""
          formControl={formControl}
          thousandSeparator
          playTransition
          inputName={AMC_FORM_NAME}
          errors={errors}
          label="AMC %"
        />
      </Grid>

      <Grid item xs={12} md={5}>
        <FormattedNumberInput
          defaultValue=""
          formControl={formControl}
          thousandSeparator
          playTransition
          inputName={TRAIL_FORM_NAME}
          errors={errors}
          label="Trail %"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <FormattedNumberInput
          defaultValue=""
          formControl={formControl}
          thousandSeparator
          playTransition
          inputName={ALLOCATION_RATE_FORM_NAME}
          errors={errors}
          label="Allocation Rate %"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <SelectMenu
          formControl={formControl}
          selectMenuName={RISK_PROFILE_FORM_NAME}
          itemArray={RISK_PROFILES}
          selectFieldName="Risk Profile"
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <FormattedNumberInput
          defaultValue=""
          formControl={formControl}
          playTransition
          prefix="€"
          inputName={EMPLOYEE_CONTRIBUTION_FORM_NAME}
          errors={errors}
          label="€ Employee Contribution"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TickBox formControl={formControl} label="ESG Agreed" name="ESG" />
      </Grid>
      <Grid item xs={12} md={5}>
        <TickBox
          formControl={formControl}
          label="Risk Profile Match"
          name="riskProfileMatch"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <FormattedNumberInput
          defaultValue=""
          formControl={formControl}
          playTransition
          prefix="€"
          inputName={EMPLOYER_CONTRIBUTION_FORM_NAME}
          errors={errors}
          label="€ Employer Contribution"
        />
      </Grid>
      <UserInput
        inputName="clientCompanyName"
        formControl={formControl}
        placeholder="Client Company Name"
        autoComplete="on"
      />
      <NumberSlider
        label="NRA"
        formControl={formControl}
        min={60}
        max={70}
        defaultValue={65}
        sliderName="retirementAge"
        valuetext="Normal Retire Age"
        onChange={onRetirementAgeChange}
        errors={errors}
        playTransition={false}
      />
    </>
  );
};

ExecutivePension.propTypes = {
  errors: PropTypes.object,
  formControl: PropTypes.func,
  setValue: PropTypes.func,
  currentPension: PropTypes.string,
};
