import { AlignmentType, Table, TableRow, WidthType } from 'docx';
import { boldTextRun, tableCell, textRun } from '../../../wordDoc.utils';

export const clientAdvisorLifeOfAnother = ({
  mortgageProtectionType,
  financialAdvisor,
  clientFullNames,
  todaysDate,
  lifeOfAnotherInput,
}) =>
  new Table({
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [
      new TableRow({
        children: [
          tableCell({
            marginTop: 0.1,
            children: [
              boldTextRun({
                text: 'Financial Advisor: ',
              }),
            ],
          }),
          tableCell({
            children: [textRun({ text: financialAdvisor })],
          }),

          tableCell({
            alignment: AlignmentType.RIGHT,
            children: [
              boldTextRun({
                text: `Date: `,
              }),
              textRun({
                text: todaysDate(),
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          tableCell({
            marginTop: 0.15,
            children: [boldTextRun({ text: 'Life Assured: ' })],
          }),
          tableCell({
            children: [
              textRun({
                text: clientFullNames,
              }),
            ],
            columnSpan: 2,
          }),
        ],
      }),
      new TableRow({
        children: [
          tableCell({
            marginTop: 0.15,
            children: [boldTextRun({ text: 'Policy Owner: ' })],
          }),
          tableCell({
            children: [
              textRun({
                text: lifeOfAnotherInput,
              }),
            ],
            columnSpan: 2,
          }),
        ],
      }),
      new TableRow({
        children: [
          tableCell({
            marginTop: 0.15,
            children: [
              boldTextRun({
                text: 'Recommended',
              }),
              boldTextRun({
                text: 'Product: ',
                lineBreak: 1,
              }),
            ],
          }),
          tableCell({
            children: [
              textRun({
                text: mortgageProtectionType,
              }),
            ],
            columnSpan: 2,
          }),
        ],
      }),
    ],
  });
