export const populateArray = ({ singlePremiumResult }) => [
  {
    label: 'Initial Commission:',
    value: singlePremiumResult.initialCommission,
    key: 'initialCommission',
  },
  {
    label: 'Net Allocations',
    value: singlePremiumResult.netAllocation,
    key: 'netAllocations',
  },
  {
    label: 'Trail',
    value: singlePremiumResult.trail,
    key: 'trail',
  },
  {
    label: 'Total AMC incl trail:',
    value: singlePremiumResult.totalAMC,
    key: 'totalAMC',
  },
  {
    label: 'Exit Charges:',
    value: singlePremiumResult.exitCharges,
    key: 'exitCharges',
  },
  {
    label: 'Fund switches:',
    value: singlePremiumResult.fundSwitches,
    key: 'fundSwitches',
  },
  {
    label: 'Commission Code:',
    value: singlePremiumResult.commissionCode,
    key: 'commissionCode',
  },
  {
    label: 'Additional AMC for Multi Asset Fund:',
    value: singlePremiumResult.additionalAMC,
    key: 'additionalAMC',
  },
];
