import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu';
import { COVER_TYPES } from '../../CommissionStructure.constant';
import { PREMIUM_TYPE, PRODUCT_TYPE } from '../../../../constants/form';

export const Step2 = ({
  control,
  errors = {},
  coverType,
  commissionStructures,
  productType,
}) => (
  <Grid container xs={12} style={{ gap: 15 }} spacing={20}>
    <SelectMenu
      errors={errors}
      formControl={control}
      selectMenuName="productType"
      itemArray={commissionStructures.filter(
        (item) =>
          item.dropdown === PRODUCT_TYPE &&
          item.dependencies.includes(coverType)
      )}
      selectFieldName="Product Type"
      playTransition={false}
    />
    {coverType !== COVER_TYPES.PROTECTION && (
      <SelectMenu
        errors={errors}
        formControl={control}
        selectMenuName="premiumType"
        itemArray={commissionStructures.filter(
          (item) =>
            item.dropdown === PREMIUM_TYPE &&
            item.dependencies.includes(productType)
        )}
        selectFieldName="Premium Type"
        playTransition={false}
      />
    )}
  </Grid>
);

Step2.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  coverType: PropTypes.string,
  productType: PropTypes.string,
  commissionStructures: PropTypes.array,
};
