import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { BASIS, INSURANCE_COMPANIES } from '../../../../data/LifeInsurance';
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu';
import { FormattedNumberInput } from '../../../../components/FormattedNumberInput/FormattedNumberInput';
import { removeSpace } from '../../../../utils/utils';
import { TickBox } from '../../../../components/TickBox/TickBox';
import { Clients } from '../Clients/Clients';
import { TYPE } from '../../../../constants/form';

export const WholeOfLifeInsurance = ({
  formControl,
  watch,
  errors,
  setValue,
}) => {
  const companyName = watch('companyName');
  const protection = watch(TYPE);
  const basis = watch('basis');

  return (
    <>
      <SelectMenu
        errors={errors}
        formControl={formControl}
        selectMenuName="basis"
        itemArray={BASIS[removeSpace(protection)]}
        selectFieldName="Basis"
      />
      {basis && (
        <SelectMenu
          formControl={formControl}
          selectMenuName="companyName"
          itemArray={INSURANCE_COMPANIES[removeSpace(protection)]}
          selectFieldName="Company Name"
          errors={errors}
        />
      )}
      {companyName && (
        <>
          <Grid xs={12} md={5}>
            <FormattedNumberInput
              prefix="€"
              label="€ Life Cover Amount"
              formControl={formControl}
              inputName="lifeCoverAmount"
              errors={errors}
            />
          </Grid>
        </>
      )}
      {companyName && (
        <>
          <Grid xs={12} md={5}>
            <FormattedNumberInput
              prefix="€"
              label="Premium Amount"
              formControl={formControl}
              inputName="premiumAmount"
              errors={errors}
            />
          </Grid>
          <Grid xs={12} md={5}>
            <TickBox
              formControl={formControl}
              label="Inheritance Tax"
              name="inheritanceTax"
            />
          </Grid>
          <Clients
            errors={errors}
            formControl={formControl}
            watch={watch}
            setValue={setValue}
          />
        </>
      )}
    </>
  );
};

WholeOfLifeInsurance.propTypes = {
  formControl: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
};
