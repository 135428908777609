import { clientAdvisorBehalfOfTable } from './clientAdvisorBehalfTable';
import { clientAdvisorTable } from './clientAdvisorTable';
import { clientAdvisorLifeOfAnother } from './clientAdvisorLifeOfAnother';

export const ClientAdvisor = ({
  financialAdvisor,
  clientFullNames,
  mortgageProtectionType,
  clientCompanyName = '',
  lifeOfAnotherInput,
}) => {
  const todaysDate = () => {
    const date = new Date();
    const day = date.toLocaleString('default', { day: '2-digit' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.toLocaleString('default', { year: 'numeric' });
    return `${day} ${month} ${year}`;
  };

  if (clientCompanyName) {
    return clientAdvisorBehalfOfTable({
      financialAdvisor,
      clientFullNames,
      mortgageProtectionType,
      clientCompanyName,
      todaysDate,
    });
  }

  if (lifeOfAnotherInput) {
    return clientAdvisorLifeOfAnother({
      financialAdvisor,
      clientFullNames,
      mortgageProtectionType,
      lifeOfAnotherInput,
      todaysDate,
    });
  }

  return clientAdvisorTable({
    financialAdvisor,
    clientFullNames,
    todaysDate,
    mortgageProtectionType,
  });
};
