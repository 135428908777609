import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import APP_SYNC_CONFIG from './AppSync';

export const GraphQLClient = new AWSAppSyncClient({
  url: APP_SYNC_CONFIG.aws_appsync_graphqlEndpoint,
  region: APP_SYNC_CONFIG.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: APP_SYNC_CONFIG.aws_appsync_apiKey,
  },
});
