import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import {
  BASIS,
  INSURANCE_COMPANIES,
  DEFERRED_PERIOD,
} from '../../../../data/LifeInsurance';
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu';
import { FormattedNumberInput } from '../../../../components/FormattedNumberInput/FormattedNumberInput';
import { NumberSlider } from '../../../../components/NumberSlider/NumberSlider';
import { FormLabel } from '../../../../components/FormLabel/FormLabel';
import { removeSpace } from '../../../../utils/utils';
import { Clients } from '../Clients/Clients';
import { EXECUTIVE, TYPE } from '../../../../constants/form';

export const IncomeProtectionInsurance = ({
  formControl,
  watch,
  errors,
  setValue,
}) => {
  const companyName = watch('companyName');
  const protection = watch(TYPE);
  const basis = watch('basis');

  const onNRAChange = (value) => {
    setValue('NRA', value);
  };

  return (
    <>
      <FormLabel label="NRA" />
      <NumberSlider
        formControl={formControl}
        sliderName="NRA"
        step={1}
        min={60}
        defaultValue={65}
        onChange={onNRAChange}
        max={70}
      />
      <SelectMenu
        errors={errors}
        formControl={formControl}
        selectMenuName="basis"
        itemArray={BASIS[removeSpace(protection)]}
        selectFieldName="Basis"
      />
      {basis && (
        <SelectMenu
          formControl={formControl}
          selectMenuName="companyName"
          itemArray={INSURANCE_COMPANIES[removeSpace(protection)]}
          selectFieldName="Company Name"
          errors={errors}
        />
      )}
      {companyName && (
        <>
          <Grid xs={12} md={5}>
            <FormattedNumberInput
              label="€ Premium Amount"
              formControl={formControl}
              inputName="premiumAmount"
              errors={errors}
              prefix="€"
              setValue={setValue}
            />
          </Grid>
          <Grid xs={12} md={5}>
            <FormattedNumberInput
              label="€ Benefit Amount"
              formControl={formControl}
              inputName="benefitAmount"
              prefix="€"
              errors={errors}
              setValue={setValue}
            />
          </Grid>
          <Grid xs={12} md={5}>
            <SelectMenu
              itemArray={DEFERRED_PERIOD}
              formControl={formControl}
              errors={errors}
              isRequired
              selectFieldName="Deferred Period"
              selectMenuName="deferredPeriod"
            />
          </Grid>
          <Clients
            errors={errors}
            formControl={formControl}
            watch={watch}
            setValue={setValue}
            showCompanyName={basis === EXECUTIVE}
          />
        </>
      )}
    </>
  );
};

IncomeProtectionInsurance.propTypes = {
  formControl: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
};
