import { riskProfileMap } from '../RetailMasterTrust/data/executivePage2';

export const filterStatements = ({
  statements,
  insuranceProvider,
  type,
  ESG,
  riskProfile,
}) =>
  statements.filter(
    (statement) =>
      ESG === statement.ESG &&
      statement.type === type &&
      statement.insuranceProvider === insuranceProvider &&
      riskProfileMap[riskProfile] === statement.riskProfile
  );
