export const termProtectionAvivaBenefits = [
  {
    text: 'Aviva Life & Pensions offer a free add on known as ‘Aviva Care’. Aviva Care gives you four health and wellbeing services that line up to offer total protection for you and your family. When your health defences are down – their Digital GPs are there to provide an online diagnosis at home or away, even provide prescriptions. When you need the support of a whole team behind you, you can get your case reviewed by one of 50,000 medical experts from around the world. In addition, because mental health is just as important as your physical health, they have a team of professional psychologists offering confidential support. Details of ‘Aviva Care’ will be include in your policy documentation.',
    level: 0,
  },
  {
    text: 'Aviva Life & Pensions provide a professional service with an efficient underwriting department.',
    level: 0,
  },
  {
    text:
      'Aviva Life & Pensions is the world’s fifth largest insurance group, serving 50 million customers\n' +
      'across Europe, North America and Asia Pacific.',
    level: 0,
  },
  {
    text:
      'Aviva Life & Pensions have a strong reputation in Ireland. Their activities include pensions and life\n' +
      'assurance, managing investments, general insurance and personal financial services.',
    level: 0,
  },
  {
    text: 'Within the current market, Aviva Life & Pensions offer very competitive premiums.',
    level: 0,
  },
];

export const termProtectionIrishLifeBenefits = [
  {
    text:
      'Established in Ireland in 1939, Irish Life is now part of the Great-West Lifeco group of companies,\n' +
      'one of the world’s leading life assurance organisations. Great-West Lifeco and its subsidiaries,\n' +
      'including The Great-West Life Assurance Company, have a record for financial strength, earnings\n' +
      'stability and consistently high ratings from the independent rating agencies. The Great-West Life\n' +
      'Assurance Company has an AA rating for insurer financial strength from Standard Poors.',
    level: 0,
  },
  {
    text: 'Irish Life are the largest provider of protection and pension benefits to the population of Ireland.',
    level: 0,
  },
  {
    text: 'Irish Life offer LifeCare at no additional cost, this includes MedCare, NurseCare and ClaimsCare',
    level: 0,
  },
  {
    text:
      'MedCare is a Medical Second Opinion service provided by MediGuide. In the unfortunate event\n' +
      'that you or a loved one are diagnosed with a medical condition there is sure to be plenty of things\n' +
      'on your mind. You will undoubtedly want to know as much as possible about your diagnosis and\n' +
      'you may want to seek more medical advice on the right treatment plan for you. That’s where\n' +
      'MedCare comes in. NurseCare is a helpline service provided by Intana Assist which gives you\n' +
      '24/7 access to experienced nursing professionals as well as a dedicated Women’s Health\n' +
      'Centre. ClaimsCare is a service which supports you and your family if you need to make a claim.\n' +
      'A dedicated Irish Life claims assessor will assist you throughout the entire claim process and you\n' +
      'can also avail of counselling sessions provided by the Clanwilliam Institute. Further details of\n' +
      'LifeCare will be included in your policy documentation.',
    level: 0,
  },
  {
    text: 'Within the current market, Irish Life offer very competitive regular premiums.',
    level: 0,
  },
];

export const termProtectionNewIrelandBenefits = [
  {
    text:
      'New Ireland was established in 1918 and was the first wholly Irish owned life assurance company\n' +
      'to transact business in the country. They are now a wholly owned subsidiary of the Bank of\n' +
      'Ireland Group which is one of the largest financial institutions in the country.',
    level: 0,
  },
  {
    text: 'New Ireland have an excellent claims paying record and reputation for customer service.',
    level: 0,
  },
  {
    text: 'New Ireland provide a professional service with an efficient underwriting department.',
    level: 0,
  },
  {
    text: 'Within the current market, New Ireland offer very competitive regular premiums.',
    level: 0,
  },
];

export const royalLondonBenefits = ({ isDualLife = false }) => {
  const royalLondon = [
    {
      text:
        'Royal London offer a free add on known as Helping Hand. Helping Hand gives one-to-one\n' +
        'personal support from your own Personal Nurse Advisor from RedArc, who can help you and\n' +
        'your family cope with the devastating effects that illness or bereavement can have. With over 20\n' +
        "years' experience, RedArc has earned a reputation for service excellence and supporting\n" +
        'individuals and their families through serious illness, chronic health conditions, bereavement and\n' +
        'disabilities. More details for this service will be included in your policy documents.',
      level: 0,
    },
    {
      text: 'Royal London provide a professional service with an efficient underwriting department.',
      level: 0,
    },
    {
      text: 'Royal London have a strong reputation in Ireland with a good claims history.',
      level: 0,
    },
    {
      text:
        'Royal London are owned by The Royal London Mutual Insurance Society Limited and are part of\n' +
        'a Group that employs over 4,300 people, provides around 8.6 million policies and is financially\n' +
        'strong and with more than €164.3 billion in Group funds under management. (Figures as of June\n' +
        '2020.)',
      level: 0,
    },
    {
      text:
        'Within the current market, Royal London offer the most competitive premium available to us from\n' +
        'the providers we are authorised to engage with.',
      level: 0,
    },
  ];

  if (isDualLife) {
    royalLondon.unshift({
      text:
        'Royal London currently offer dual life mortgage protection for the same price as joint life\n' +
        'mortgage protection.',
      level: 0,
    });
  }

  return royalLondon;
};

export const termProtectionZurichLifeBenefits = [
  {
    text:
      "Zurich Life is one of Ireland's largest Pension, Investment and Protection Providers. Zurich Life is\n" +
      'a member of the Zurich Insurance Group, a multi-line insurer serving customers in more than 210\n' +
      'countries and territories.',
    level: 0,
  },
  {
    text:
      'Zurich Life has a well-established and well-deserved reputation for first-rate service. Zurich has\n' +
      'won many Professional Insurance Brokers Association awards for Service Excellence in recent\n' +
      'years.',
    level: 0,
  },
  {
    text: 'Zurich Life provide a professional service with an efficient underwriting department.',
    level: 0,
  },
  {
    text: 'Zurich Life have a strong reputation in Ireland with a good claims history.',
    level: 0,
  },
  {
    text: 'Within the current market, Zurich Life offer very competitive regular premiums.',
    level: 0,
  },
];

export const incomeProtectionAvivaBenefits = [
  {
    text: 'Aviva Life & Pensions offer a free add on known as ‘Aviva Care’. Aviva Care gives you four health and wellbeing services that line up to offer total protection for you and your family. When your health defences are down – their Digital GPs are there to provide an online diagnosis at home or away, even provide prescriptions. When you need the support of a whole team behind you, you can get your case reviewed by one of 50,000 medical experts from around the world. In addition, because mental health is just as important as your physical health, they have a team of professional psychologists offering confidential support. Details of ‘Aviva Care’ will be include in your policy documentation.',
    level: 0,
  },
  {
    text: 'Aviva provide a professional service with an efficient underwriting department.',
    level: 0,
  },
  {
    text: 'Aviva is the world’s fifth largest insurance group, serving 50 million customers across Europe, North America and Asia Pacific.',
    level: 0,
  },
  {
    text: 'Aviva have a strong reputation in Ireland. Their activities include pensions and life assurance, managing investments, general insurance and personal financial services.',
    level: 0,
  },
  {
    text: 'Within the current market, Aviva Life offer very competitive premiums.',
    level: 0,
  },
];
