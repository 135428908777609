import { EXECUTIVE_PENSION } from '../../../constants/form';

export const PENSIONS_TYPES = [
  {
    value: EXECUTIVE_PENSION,
    label: EXECUTIVE_PENSION,
  },
];

export const PENSION_COMPANIES = {
  Executive: [
    { value: 'Aviva Life & Pensions', label: 'Aviva Life' },
    { value: 'New Ireland', label: 'New Ireland' },
    { value: 'Irish Life', label: 'Irish Life' },
    { value: 'Zurich Life', label: 'Zurich Life' },
    { value: 'Standard Life', label: 'Standard Life' },
  ],
};

export const RISK_PROFILES = [
  { value: 'Very Low', label: 1 },
  { value: 'Low', label: 2 },
  { value: 'Low to Medium', label: 3 },
  { value: 'Medium', label: 4 },
  { value: 'Medium to High', label: 5 },
  { value: 'High', label: 6 },
  { value: 'Very High', label: 7 },
];

export const COMPANY_FORM_NAME = 'companyName';
export const RISK_PROFILE_FORM_NAME = 'riskProfile';
export const EMPLOYEE_CONTRIBUTION_FORM_NAME = 'employeeContribution';
export const EMPLOYER_CONTRIBUTION_FORM_NAME = 'employerContribution';
export const AMC_FORM_NAME = 'amc';
export const TRAIL_FORM_NAME = 'trail';
export const ALLOCATION_RATE_FORM_NAME = 'allocationRate';
