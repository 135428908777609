import {
  EXECUTIVE,
  INCOME_PROTECTION,
  LEVEL_TERM,
  MORTGAGE_PROTECTION,
  NONE,
  TERM_PROTECTION,
  WHOLE_OF_LIFE,
} from '../../../../constants/form';

export const termProtectionAvivaAddedBenefits = ({
  seriousIllness,
  insuranceType,
  term,
}) => {
  const benefits = [];
  if (insuranceType !== MORTGAGE_PROTECTION) {
    benefits.push({
      text: 'Option to escalate your premium and sum(s) insured each year to protect against inflation',
      level: 0,
    });
  }

  if (insuranceType === LEVEL_TERM || insuranceType === MORTGAGE_PROTECTION) {
    benefits.push({
      text: `Extension of cover beyond the ${term} years, without providing further medical evidence.`,
      level: 0,
    });
  }

  if (seriousIllness === NONE) {
    benefits.splice(0, 0, { text: 'Serious illness cover.', level: 0 });
  }
  return benefits;
};

export const termProtectionRoyalLondonAddedBenefits = ({
  insuranceType,
  term,
  seriousIllness,
}) => {
  const benefits = [];
  if (insuranceType !== MORTGAGE_PROTECTION) {
    benefits.push({
      text: 'Option to escalate your premium and sum(s) insured each year to protect against inflation',
      level: 0,
    });
  }

  if (insuranceType === LEVEL_TERM || insuranceType === MORTGAGE_PROTECTION) {
    benefits.push({
      text: `Extension of cover beyond the ${term} years, without providing further medical evidence.`,
      level: 0,
    });
  }

  if (seriousIllness === NONE) {
    benefits.splice(0, 0, { text: 'Serious illness cover.', level: 0 });
  }
  return benefits;
};

export const termProtectionIrishLifeAddedBenefits = ({
  insuranceType,
  term,
  seriousIllness,
}) => {
  const benefits = [];
  if (insuranceType !== MORTGAGE_PROTECTION) {
    benefits.push({
      text: 'Option to escalate your premium and sum(s) insured each year to protect against inflation',
      level: 0,
    });
  }

  if (insuranceType === LEVEL_TERM || insuranceType === MORTGAGE_PROTECTION) {
    benefits.push({
      text: `Extension of cover beyond the ${term} years, without providing further medical evidence.`,
      level: 0,
    });
  }

  if (seriousIllness === NONE) {
    benefits.splice(0, 0, { text: 'Serious illness cover.', level: 0 });
  }
  return benefits;
};

export const termProtectionNewIrelandAddedBenefits = ({
  seriousIllness,
  insuranceType,
  term,
}) => {
  const benefits = [];

  if (insuranceType !== MORTGAGE_PROTECTION) {
    benefits.push(
      { text: 'Monthly income replacement.', level: 0 },
      { text: 'Daily hospital cash payment.', level: 0 },
      { text: 'Weekly personal accident payment.', level: 0 },
      { text: 'Broken Bones Payment.', level: 0 },
      { text: 'Whole of Life Continuation option.', level: 0 },
      {
        text: 'Option to escalate your premium and sum(s) insured each year to protect against inflation.',
        level: 0,
      }
    );

    if (seriousIllness === NONE) {
      benefits.push({
        text: 'Serious Illness Cover.',
        level: 0,
      });
    } else {
      benefits.push({
        text: 'Surgical Cash Benefit.',
        level: 0,
      });
    }
  }

  if (insuranceType === LEVEL_TERM || insuranceType === MORTGAGE_PROTECTION) {
    benefits.push({
      text: `Extension of cover beyond the ${term} years, without providing further medical evidence.`,
      level: 0,
    });
  }

  return benefits;
};

export const termProtectionZurichLifeAddedBenefits = ({
  seriousIllness,
  insuranceType,
  term,
}) => {
  const benefits = [];

  if (insuranceType !== MORTGAGE_PROTECTION) {
    benefits.push(
      { text: 'Monthly income replacement.', level: 0 },
      { text: 'Daily hospital cash payment.', level: 0 },
      { text: 'Weekly personal accident payment.', level: 0 },
      { text: 'Waiver of premium.', level: 0 },
      {
        text: 'Option to escalate your premium and sum(s) insured each year to protect against inflation.',
        level: 0,
      }
    );

    if (seriousIllness === NONE) {
      benefits.push({
        text: "Serious Illness (with or without Permanent Total Disability 'Own' Occupation) or Cancer Cover. A surgical cash benefit can be added if one of these two is selected.",
        level: 0,
      });
    } else {
      benefits.push({
        text: 'Surgical Cash Benefit.',
        level: 0,
      });
    }
  }

  if (insuranceType === LEVEL_TERM || insuranceType === MORTGAGE_PROTECTION) {
    benefits.push({
      text: `Extension of cover beyond the ${term} years, without providing further medical evidence.`,
      level: 0,
    });
  }

  return benefits;
};

export const incomeProtectionAvivaAddedBenefits = ({ basis }) => {
  const benefits = [
    {
      text: 'Option to escalate your premium and sum(s) insured each year to protect against inflation.',
      level: 0,
    },
    { text: 'Benefit Escalation in claim.', level: 0 },
  ];
  if (basis === EXECUTIVE) {
    benefits.push({ text: 'Pension Contribution Cover.', level: 0 });
  }
  return benefits;
};

export const wholeOfLifeAddedBenefits = ({ companyName }) => {
  const benefits = [
    {
      text: 'Option to escalate your premium and sum(s) insured each year to protect against inflation.',
      level: 0,
    },
  ];
  if (companyName === 'Royal London') {
    benefits.unshift({
      text: 'You can add Royal London’s Life Changes Option to your policy for an extra 10% on your premium. This unique option is all about giving you choice and control should your circumstances change in the future. So, under this option, you can stop your premium payments after having had your policy for 15 years or more – and still get something back.',
      level: 0,
    });
  }
  return benefits;
};

export const addedBenefits = ({
  seriousIllness = '',
  basis = '',
  term = 0,
  protection = '',
  companyName = '',
  insuranceType = '',
}) => {
  if (protection === TERM_PROTECTION) {
    return {
      AvivaLifePensions: termProtectionAvivaAddedBenefits({
        seriousIllness,
        insuranceType,
        term,
      }),
      NewIreland: termProtectionNewIrelandAddedBenefits({
        seriousIllness,
        insuranceType,
        term,
      }),
      IrishLife: termProtectionIrishLifeAddedBenefits({
        seriousIllness,
        term,
        insuranceType,
      }),
      ZurichLife: termProtectionZurichLifeAddedBenefits({
        seriousIllness,
        insuranceType,
      }),
      RoyalLondon: termProtectionRoyalLondonAddedBenefits({
        insuranceType,
        term,
        seriousIllness,
      }),
    };
  }
  if (protection === INCOME_PROTECTION) {
    return {
      AvivaLifePensions: incomeProtectionAvivaAddedBenefits({
        basis,
        insuranceType,
      }),
      NewIreland: incomeProtectionAvivaAddedBenefits({
        basis,
        insuranceType,
      }),
      IrishLife: incomeProtectionAvivaAddedBenefits({
        basis,
        insuranceType,
      }),
      ZurichLife: incomeProtectionAvivaAddedBenefits({
        basis,
        insuranceType,
      }),
      RoyalLondon: incomeProtectionAvivaAddedBenefits({
        basis,
        insuranceType,
      }),
    };
  }
  if (protection === WHOLE_OF_LIFE) {
    return {
      AvivaLifePensions: wholeOfLifeAddedBenefits({
        companyName,
      }),
      NewIreland: wholeOfLifeAddedBenefits({
        companyName,
      }),
      IrishLife: wholeOfLifeAddedBenefits({
        companyName,
      }),
      ZurichLife: wholeOfLifeAddedBenefits({
        companyName,
      }),
      RoyalLondon: wholeOfLifeAddedBenefits({
        companyName,
      }),
    };
  }
  return null;
};
