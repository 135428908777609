import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { populateArray } from './tableRows';

export const PensionRegularPremiumContent = ({
  convertedRegularPremium,
  regularPremiumResult,
  regularPremiumConfig = {},
  retirementAge,
  age,
}) => {
  const isTooCloseToRetirement =
    retirementAge - age <= regularPremiumConfig?.minimumTerm;
  if (
    convertedRegularPremium < regularPremiumConfig?.minAmount ||
    isTooCloseToRetirement
  ) {
    return <p>{regularPremiumConfig.outsideRangeMessage}</p>;
  }

  if (!regularPremiumResult) {
    return <p>No Pension Commission Structure Available</p>;
  }
  const tableRows = populateArray({ regularPremiumResult });
  return (
    <Table>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={row.key}>
            <TableCell style={{ width: 160 }} align="left">
              {row.label}
            </TableCell>
            <TableCell style={{ width: 160 }} align="left">
              {row.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

PensionRegularPremiumContent.propTypes = {
  convertedRegularPremium: PropTypes.number,
  regularPremiumResult: PropTypes.object,
  age: PropTypes.number,
  retirementAge: PropTypes.number,
  regularPremiumConfig: PropTypes.object,
};
