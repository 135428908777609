import React from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from '@material-ui/lab';
import { findProtectionResult } from '../step4.utils';
import { ProtectionSinglePremiumContent } from './ProtectionSinglePremiumContent/ProtectionSinglePremiumContent';

export const Protection = ({
  commissionStructures,
  insuranceProvider,
  productType,
  coverType,
}) => {
  const protectionResult = findProtectionResult({
    commissionStructures,
    productType,
    insuranceProvider,
    coverType,
  });

  return (
    <TabPanel value={3}>
      <ProtectionSinglePremiumContent protectionResult={protectionResult} />
    </TabPanel>
  );
};

Protection.propTypes = {
  commissionStructures: PropTypes.string,
  insuranceProvider: PropTypes.string,
  productType: PropTypes.string,
  coverType: PropTypes.string,
};
