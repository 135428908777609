import {
  BorderStyle,
  convertInchesToTwip,
  Table,
  TableCell,
  TableRow,
} from 'docx';
import { boldTextRun, paragraphWithTextRun } from '../../../wordDoc.utils';

export const signature = ({
  textUnderSigned = '',
  secondLine = '',
  lineBreak = 1,
}) => {
  if (!textUnderSigned) {
    return {};
  }
  return new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              paragraphWithTextRun({
                children: [
                  boldTextRun({
                    text: 'Signed: ___________________________',
                    lineBreak,
                  }),
                ],
              }),
              paragraphWithTextRun({
                children: [
                  boldTextRun({
                    text: textUnderSigned,
                    size: 20,
                  }),
                ],
                indentLeft: convertInchesToTwip(0.5),
              }),
              paragraphWithTextRun({
                children: [
                  boldTextRun({
                    text: secondLine,
                    size: 20,
                  }),
                ],
                indentLeft: convertInchesToTwip(0.5),
              }),
            ],
            borders: {
              top: {
                style: BorderStyle.NONE,
                size: 1,
              },
              bottom: {
                style: BorderStyle.NONE,
                size: 1,
              },
              left: {
                style: BorderStyle.NONE,
                size: 1,
              },
              right: {
                style: BorderStyle.NONE,
                size: 1,
              },
            },
          }),
          new TableCell({
            children: [
              paragraphWithTextRun({
                children: [
                  boldTextRun({
                    text: 'Date: ___________ ',
                    lineBreak: 1,
                  }),
                ],
              }),
            ],
            margins: {
              left: convertInchesToTwip(1),
            },
            borders: {
              top: {
                style: BorderStyle.NONE,
                size: 1,
              },
              bottom: {
                style: BorderStyle.NONE,
                size: 1,
              },
              left: {
                style: BorderStyle.NONE,
                size: 1,
              },
              right: {
                style: BorderStyle.NONE,
                size: 1,
              },
            },
          }),
        ],
      }),
    ],
  });
};
