import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HastingsLogo from '../../assets/images/HastingsLogo.png';
import Theme from '../../constants/theme';
import useAccount from '../../providers/AccountProvider/useAccount';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: Theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textAlign: 'center',
    },
    logo: {
      maxWidth: 100,
      marginRight: Theme.spacing(2),
      display: 'flex',
    },
    link: {
      textDecoration: 'none',
    },
    button: {
      marginRight: Theme.spacing(2),
    },
  })
);

export const NavBar = () => {
  const classes = useStyles();
  const location = useLocation();

  const { logout, isAuthenticated } = useAccount();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: 'white' }}>
        <Toolbar>
          {isAuthenticated && (
            <>
              <Button
                color="primary"
                onClick={logout}
                className={classes.button}
              >
                Logout
              </Button>
              {location.pathname !== '/Dashboard' && (
                <Button color="primary" className={classes.button}>
                  <Link to="/Dashboard" className={classes.link}>
                    Home
                  </Link>
                </Button>
              )}
            </>
          )}
          <Typography variant="h6" className={classes.title} />

          <img
            src={HastingsLogo}
            alt="Hastings Logo"
            className={classes.logo}
            draggable={false}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
};
