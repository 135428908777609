import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@material-ui/core';
import { TabContext, TabList } from '@material-ui/lab';
import { Pension } from './Pension/Pension';
import { COVER_TYPES, PREMIUM_TYPES } from '../../CommissionStructure.constant';
import { SavingsInvestments } from './SavingsInvestments/SavingsInvestments';
import { Protection } from './Protection/Protection';
import {
  COVER_TYPE,
  INSURANCE_PROVIDERS,
  PREMIUM_TYPE,
  PRODUCT_TYPE,
} from '../../../../constants/form';

export const Step4 = ({ commissionStructures, watch, setValue }) => {
  const singlePremium = watch('singlePremium');
  const regularPremium = watch('regularPremium');
  const productType = watch(PRODUCT_TYPE);
  const coverType = watch(COVER_TYPE);
  const dateOfBirth = watch('dateOfBirth');
  const insuranceProvider = watch(INSURANCE_PROVIDERS);
  const retirementAge = watch('retirementAge');
  const premiumType = watch(PREMIUM_TYPE);

  useEffect(() => {
    if (coverType === COVER_TYPES.PROTECTION) {
      setValue('singlePremium', '');
      setValue('regularPremium', '');
    }
    if (
      coverType === COVER_TYPES.SAVINGS_INVESTMENTS &&
      premiumType === PREMIUM_TYPES.REGULAR_PREMIUM
    ) {
      setValue('singlePremium', '');
    }

    if (
      coverType === COVER_TYPES.SAVINGS_INVESTMENTS &&
      premiumType === PREMIUM_TYPES.SINGLE_PREMIUM
    ) {
      setValue('regularPremium', '');
    }
  }, [coverType]);

  const isSinglePremium =
    premiumType === PREMIUM_TYPES.BOTH ||
    premiumType === PREMIUM_TYPES.SINGLE_PREMIUM;

  const isRegularPremium =
    premiumType === PREMIUM_TYPES.BOTH ||
    premiumType === PREMIUM_TYPES.REGULAR_PREMIUM;

  const isProtection = coverType === COVER_TYPES.PROTECTION;

  const tabList = [];

  const getTabs = () => {
    if (isSinglePremium) {
      tabList.push({ label: PREMIUM_TYPES.SINGLE_PREMIUM, value: 1 });
    }
    if (isRegularPremium) {
      tabList.push({ label: PREMIUM_TYPES.REGULAR_PREMIUM, value: 2 });
    }
    if (isProtection) {
      tabList.push({ label: COVER_TYPES.PROTECTION, value: 3 });
    }
  };

  getTabs();

  const [selectedTab, setSelectedTab] = useState(
    tabList[0] ? tabList[0].value : 1
  );

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <TabList
        onChange={handleTabChange}
        aria-label="commission structure tabs"
      >
        {tabList.map((tab) => (
          <Tab label={tab.label} value={tab.value} />
        ))}
      </TabList>
      {coverType === COVER_TYPES.PENSION && (
        <Pension
          insuranceProvider={insuranceProvider}
          retirementAge={retirementAge}
          commissionStructures={commissionStructures}
          dateOfBirth={dateOfBirth}
          productType={productType}
          regularPremium={regularPremium}
          singlePremium={singlePremium}
        />
      )}
      {coverType === COVER_TYPES.SAVINGS_INVESTMENTS && (
        <SavingsInvestments
          insuranceProvider={insuranceProvider}
          commissionStructures={commissionStructures}
          productType={productType}
          regularPremium={regularPremium}
          singlePremium={singlePremium}
          coverType={coverType}
        />
      )}
      {coverType === COVER_TYPES.PROTECTION && (
        <Protection
          insuranceProvider={insuranceProvider}
          commissionStructures={commissionStructures}
          productType={productType}
          coverType={coverType}
        />
      )}
    </TabContext>
  );
};

Step4.propTypes = {
  watch: PropTypes.func,
  setValue: PropTypes.func,
  commissionStructures: PropTypes.array,
};
