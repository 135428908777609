import React from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from '@material-ui/lab';
import { PensionSinglePremiumContent } from './PensionSinglePremiumContent/PensionSinglePremiumContent';
import { PensionRegularPremiumContent } from './PensionRegularPremiumContent/PensionRegularPremiumContent';
import {
  convertStringCurrencyToNumber,
  getAge,
} from '../../../../../utils/utils';
import { findPensionResult, getInsuranceProviderConfig } from '../step4.utils';
import {
  PREMIUM_TYPES,
  PRODUCT_TYPES,
} from '../../../CommissionStructure.constant';

export const Pension = ({
  commissionStructures,
  singlePremium,
  regularPremium,
  insuranceProvider,
  productType,
  dateOfBirth,
  retirementAge,
}) => {
  const isARF = productType === PRODUCT_TYPES.ARF;

  const convertedSinglePremium = convertStringCurrencyToNumber(singlePremium);
  const convertedRegularPremium = convertStringCurrencyToNumber(regularPremium);
  const singlePremiumConfig = getInsuranceProviderConfig({
    commissionStructures,
    insuranceProvider,
    premiumType: PREMIUM_TYPES.SINGLE_PREMIUM,
    productType,
  });

  const regularPremiumConfig = getInsuranceProviderConfig({
    commissionStructures,
    insuranceProvider,
    premiumType: PREMIUM_TYPES.REGULAR_PREMIUM,
    productType,
  });

  const age = getAge(dateOfBirth);

  const singlePremiumResult = findPensionResult({
    commissionStructures,
    premiumType: PREMIUM_TYPES.SINGLE_PREMIUM,
    premium: convertedSinglePremium,
    age: isARF ? 65 : age,
    insuranceProvider,
    productType,
  });

  const regularPremiumResult = findPensionResult({
    commissionStructures,
    premiumType: PREMIUM_TYPES.REGULAR_PREMIUM,
    premium: convertedRegularPremium,
    age: isARF ? 65 : age,
    insuranceProvider,
    productType,
  });

  return (
    <>
      <TabPanel value={1}>
        <PensionSinglePremiumContent
          convertedSinglePremium={convertedSinglePremium}
          singlePremiumResult={singlePremiumResult}
          singlePremiumConfig={singlePremiumConfig}
          retirementAge={retirementAge}
          age={age}
        />
      </TabPanel>
      <TabPanel value={2}>
        <PensionRegularPremiumContent
          convertedRegularPremium={convertedRegularPremium}
          age={age}
          regularPremiumConfig={regularPremiumConfig}
          retirementAge={retirementAge}
          regularPremiumResult={regularPremiumResult}
        />
      </TabPanel>
    </>
  );
};

Pension.propTypes = {
  commissionStructures: PropTypes.string,
  singlePremium: PropTypes.string,
  regularPremium: PropTypes.string,
  insuranceProvider: PropTypes.string,
  productType: PropTypes.string,
  dateOfBirth: PropTypes.string,
  retirementAge: PropTypes.string,
};
