export const populateArray = ({ protectionResult }) => [
  {
    label: 'Initial Year 1:',
    value: protectionResult.initialYear1,
    key: 'year1',
  },
  {
    label: 'Initial Year 2:',
    value: protectionResult.initialYear2,
    key: 'year2',
  },
  {
    label: 'Initial Year 3:',
    value: protectionResult.initialYear3,
    key: 'year3',
  },
  {
    label: 'Initial Year 4:',
    value: protectionResult.initialYear4,
    key: 'year4',
  },
  {
    label: 'Initial Year 5:',
    value: protectionResult.initialYear5,
    key: 'year5',
  },
  {
    label: 'Renewal:',
    value: protectionResult.renewal,
    key: 'renewal',
  },
];
