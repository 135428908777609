import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup, Zoom } from '@material-ui/core';
import { Controller } from 'react-hook-form';

export const RadioButton = ({
  radioGroupName,
  formControl,
  radioOptions,
  row = false,
  labelPlacement = 'left',
  playTransition = true,
}) => (
  <Controller
    render={({ field }) => (
      <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
        <RadioGroup
          row={row}
          aria-label={radioGroupName}
          name={radioGroupName}
          style={{ justifyContent: 'center' }}
          {...field}
          defaultValue={radioOptions[0].value}
        >
          {radioOptions.map((radio) => (
            <FormControlLabel
              value={radio.value}
              labelPlacement={labelPlacement}
              control={<Radio color="primary" />}
              label={radio.label}
            />
          ))}
        </RadioGroup>
      </Zoom>
    )}
    defaultValue={radioOptions[0].value}
    name={radioGroupName}
    control={formControl}
  />
);

RadioButton.propTypes = {
  radioGroupName: PropTypes.string,
  formControl: PropTypes.object,
  radioOptions: PropTypes.array,
  row: PropTypes.bool,
  playTransition: PropTypes.bool,
  labelPlacement: PropTypes.string,
};
