import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useAccount from '../../providers/AccountProvider/useAccount';
import { SEVERITY_TYPES } from '../../constants/toast';

export const ProtectedRoute = ({ component }) => {
  const { isAuthenticated } = useAccount();
  const { addToast } = useToasts();

  const history = useHistory();

  const redirect = () => history.push('/Login');

  if (!isAuthenticated) {
    addToast('Login required', {
      appearance: SEVERITY_TYPES.info,
      autoDismiss: true,
      id: 'unauthenticated',
    });
    redirect();
  }

  return component;
};
