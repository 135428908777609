import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu';
import {
  COMMISSION_STRUCTURES,
  COVER_TYPE,
  INSURANCE_PROVIDERS,
} from '../../../../constants/form';

export const Step1 = ({
  control,
  errors = {},
  coverType,
  commissionStructures,
}) => (
  <Grid container xs={12} style={{ gap: 15 }}>
    <SelectMenu
      errors={errors}
      formControl={control}
      selectMenuName={COVER_TYPE}
      itemArray={commissionStructures.filter(
        (item) => item.dropdown === COMMISSION_STRUCTURES
      )}
      selectFieldName="Cover Type"
      playTransition={false}
    />
    <SelectMenu
      errors={errors}
      formControl={control}
      selectMenuName={INSURANCE_PROVIDERS}
      itemArray={commissionStructures.filter(
        (item) =>
          item.dropdown === INSURANCE_PROVIDERS &&
          item.dependencies.includes(coverType)
      )}
      selectFieldName="Insurance Provider"
      playTransition={false}
    />
  </Grid>
);

Step1.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  coverType: PropTypes.string,
  commissionStructures: PropTypes.array,
};
