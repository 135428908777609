import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Container, Grid, Paper, Zoom } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';

import Theme from '../../constants/theme';
import { InitialSelection } from './LifeInsurance/InitialSelection/InitialSelection';
import { LifeInsurance } from './LifeInsurance/LifeInsurance';
import { generateIncomeProtection } from '../WordDocument/Insurance/generateIncomeProtectionDoc';
import { generateLifeInsurance } from '../WordDocument/Insurance/generateLifeInsuranceDoc';
import { DEFAULT_INSURANCE_VALUES } from './LifeInsurance/LifeInsurance.constant';
import {
  INCOME_PROTECTION,
  TERM_PROTECTION,
  LEVEL_TERM,
  MORTGAGE_PROTECTION,
  WHOLE_OF_LIFE,
  PENSIONS,
  PRODUCT_TYPE,
  PROTECTION,
  EXECUTIVE_PENSION,
} from '../../constants/form';
import { Pensions } from './Pensions/Pensions';
import { generateRetailMasterTrust } from '../WordDocument/Pensions/RetailMasterTrust/generateRetailMasterTrust';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: Theme.spacing(5),
      paddingBottom: Theme.spacing(5),
    },
    grid: {
      padding: '4px',
    },
    generateDocButton: {
      width: '100%',
      color: 'white',
      marginTop: Theme.spacing(5),
    },
    newClientButton: {
      backgroundColor: '#4DB543',
      color: 'white',
      width: '45%',
      '&:hover': {
        backgroundColor: '#43a33b',
      },
    },
    resetFormButton: {
      backgroundColor: '#EC4B4E',
      color: 'white',
      width: '100%',
      '&:hover': {
        transition: 'backgroundColor 20s ease-out',
        backgroundColor: '#cc494c',
      },
    },
    title: {
      textAlign: 'center;',
      paddingBottom: '16px',
      color: Theme.palette.primary.main,
    },
    paper: {
      padding: '32px',
    },
    icon: {
      marginRight: Theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    link: {
      display: 'flex',
    },
    breadcrumb: {
      marginTop: '32px',
      marginLeft: '32px',
    },
  })
);

export const StatementOfSuitability = () => {
  const [dynamicStatements, setDynamicStatement] = useState([]);
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...DEFAULT_INSURANCE_VALUES,
    },
  });

  const resetForm = () => {
    reset({
      ...DEFAULT_INSURANCE_VALUES,
    });
  };

  const updateDynamicStatement = (statements) =>
    setDynamicStatement(statements);

  const wordDocMap = {
    [INCOME_PROTECTION]: generateIncomeProtection,
    [WHOLE_OF_LIFE]: generateLifeInsurance,
    [TERM_PROTECTION]: generateLifeInsurance,
    [LEVEL_TERM]: generateLifeInsurance,
    [MORTGAGE_PROTECTION]: generateLifeInsurance,
    [EXECUTIVE_PENSION]: generateRetailMasterTrust,
  };

  const onSubmit = (data) =>
    wordDocMap[data.type]({ ...data, dynamicStatements });
  const productType = watch(PRODUCT_TYPE);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper variant="outlined" className={classes.paper} elevation={3}>
        <Typography variant="h5" className={classes.title}>
          Statement of Suitability
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.grid}
            style={{ gap: 15 }}
          >
            <InitialSelection
              formControl={control}
              errors={errors}
              watch={watch}
            />
            {productType === PROTECTION && (
              <LifeInsurance
                formControl={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
              />
            )}
            {productType === PENSIONS && (
              <Pensions
                formControl={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                updateDynamicStatement={updateDynamicStatement}
              />
            )}
            <Zoom in style={{ transitionDelay: '100ms' }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.generateDocButton}
                startIcon={<DescriptionIcon />}
              >
                Generate Document
              </Button>
            </Zoom>
            <Zoom in style={{ transitionDelay: '100ms' }}>
              <Button
                variant="contained"
                onClick={resetForm}
                className={classes.resetFormButton}
                startIcon={<DeleteIcon />}
              >
                Reset Form
              </Button>
            </Zoom>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};
