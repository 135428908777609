import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { Checkbox, FormControlLabel, Zoom } from '@material-ui/core';

export const TickBox = ({
  name,
  formControl,
  label,
  isRequired = false,
  playTransition = true,
}) => (
  <FormControlLabel
    control={
      <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
        <Controller
          name={name}
          control={formControl}
          defaultValue={false}
          rules={{ required: isRequired }}
          render={({ field }) => <Checkbox {...field} color="primary" />}
        />
      </Zoom>
    }
    label={label}
  />
);

TickBox.propTypes = {
  name: PropTypes.string,
  formControl: PropTypes.object,
  label: PropTypes.string,
  playTransition: PropTypes.bool,
  isRequired: PropTypes.bool,
};
