export const notice = 'Important Notice - Statement of Suitability';
export const header =
  'This is an important document which sets out the reasons why the product(s) or service(s) offered or recommended is/are considered suitable, or the most suitable, for your particular needs, objectives and circumstances.';

export const HASTINGS_FOOTER =
  '• Hastings (Westport) Ltd trading as Hastings Insurance, Hastings Financial & Hastings Mortgages is regulated by the Central Bank of Ireland';
export const FLANNELLY_FOOTER =
  '• David Flannelly(Holdings) Ltd trading as Hastings Insurance, Hastings Financial & Hastings Mortgages is regulated by the Central Bank of Ireland';
export const HARDIMAN_FOOTER =
  '• Pat Hardiman Insurances Ltd trading as Hastings Insurance & Hastings Financial is regulated by the Central Bank of Ireland';

export const HASTINGS_FINANCIAL_PENSIONS_FOOTER =
  '• Hastings Life & Pensions Ltd trading as Hastings Insurance & Hastings Financial is regulated by the Central Bank of Ireland';

export const thankYouIntro =
  'Thank you for taking the time to discuss your financial planning requirements with me. Following an analysis of your financial needs and requirements and based on the information with which you provided me, we have identified that you have/require the following:';

export const clientDeclarations = ({
  insuranceCompanyName,
  client2FirstName,
}) =>
  client2FirstName
    ? [
        {
          text: 'We are happy to proceed on the basis of the recommendation given to us and wish to affect the policy recommended. We also confirm we have been provided with a disclosure illustration relating to the policy. Or we are happy to proceed with the alternative option given to us and wish to effect the alternative policy.',
          level: 0,
        },
        {
          text: 'We also wish to advise that we would like to start my policy on __________ and will have sufficient funds in our account to allow this.',
          level: 0,
        },
        {
          text: 'We have read the related proposal form(s) carefully. We confirm that all the information disclosed on it, whether completed by us or not, is accurate and is to be treated as if the form had been completed by us.',
          level: 0,
        },
        {
          text: 'We confirm that we fully understand all of the questions and answers. We have disclosed everything, which might be relevant to this insurance, whether or not we have been specifically asked it.',
          level: 0,
        },
        {
          text: `We understand that it is our responsibility to ensure the cover is maintained by the payment of premiums in accordance with the ${insuranceCompanyName}'s terms.`,
          level: 0,
        },
        {
          text: 'We also understand that where applicable, we should not cancel our existing policy until the new policy has been underwritten and commenced.',
          level: 0,
        },
      ]
    : [
        {
          text: 'I am happy to proceed on the basis of the recommendation given to me and wish to affect the policy recommended. I also confirm I have been provided with a disclosure illustration relating to the policy. Or I are happy to proceed with the alternative option given to me and wish to effect the alternative policy.',
          level: 0,
        },
        {
          text: 'I also wish to advise that I would like to start my policy on __________ and will have sufficient funds in my account to allow this.',
          level: 0,
        },
        {
          text: 'I have read the related proposal form(s) carefully. I confirm that all the information disclosed on it, whether completed by me or not, is accurate and is to be treated as if the form had been completed by me.',
          level: 0,
        },
        {
          text: 'I confirm that I fully understand all of the questions and answers. I have disclosed everything, which might be relevant to this insurance, whether or not I have been specifically asked it.',
          level: 0,
        },
        {
          text: `I understand that it is my responsibility to ensure the cover is maintained by the payment of premiums in accordance with the ${insuranceCompanyName}'s terms.`,
          level: 0,
        },
        {
          text: 'I also understand that where applicable, I should not cancel my existing policy until the new policy has been underwritten and commenced.',
          level: 0,
        },
      ];

export const declarations = [
  {
    text:
      'Please note that this recommendation must be read in conjunction with the specific terms and\n' +
      'conditions for each product. Please take the time to go through the information issued to you. This contains the benefits in far greater detail. Should you have any queries, please refer them to\n' +
      'myself.',
    level: 0,
  },
  {
    text:
      'I wish to stress the importance of informing me should any of your circumstances change as this\n' +
      'may impact upon the recommendation I have made to you.',
    level: 0,
  },
  {
    text:
      'The enclosed important information about your protection policy outlines how the policy works in\n' +
      'more detail. Please read through this and refer any queries to me. I would like to draw your\n' +
      'attention to the information regarding the circumstances for claims payment and the precise\n' +
      'conditions that must exist for a claim to be paid.',
    level: 0,
  },
  {
    text:
      'Please also note that any claim is related to the information disclosed at the outset of the policy. It\n' +
      'is important to disclose all medical information as any non-disclosure may result in a claims\n' +
      'dispute. If you have any questions regarding this please refer it to myself.',
    level: 0,
  },
  {
    text:
      'If your policy includes serious illness I wish to draw your attention to the specific events that must\n' +
      'occur in order to claim the illness benefit associated with this recommended policy. This policy will\n' +
      'only pay out on an illness specifically covered in the policy and these will be outlined in the policy\n' +
      'terms and conditions sent to you by the life company.',
    level: 0,
  },
];

export const guaranteesLimitations = ({ insuranceCompanyName }) => [
  {
    text: 'The benefit of this plan is the provision of a lump sum commencing on the death.',
    level: 0,
  },
  {
    text: 'Benefits are provided by the payment of a regular premium for the term of the plan.',
    level: 0,
  },
  {
    text: `This premium cannot be reviewed by ${insuranceCompanyName} once the policy has been set up and accepted.`,
    level: 0,
  },
  {
    text:
      'This policy never acquires a cash value and is not appropriate for retirement funding or savings\n' +
      'purposes.',
    level: 0,
  },
  {
    text: `As this is a protection plan, if the premiums cease to be paid, no encashment value will be paid, and the policy will be terminated, after which ${insuranceCompanyName} will have no further liability under this policy.`,
    level: 0,
  },
  {
    text:
      'Any failure to disclose material/medical information may invalidate your claim and render you\n' +
      'policy void. You should note there is no value payable on this policy at any time',
    level: 0,
  },
];

export const guaranteesAndLimitationsIncomeProtection = ({
  insuranceCompanyName,
}) => [
  {
    text: 'The benefit of this plan is the payment of an annual income to replace your loss of existing income in the event of accident or illness, commencing after the chosen deferred period.',
    level: 0,
  },
  {
    text: 'Benefits are provided by the payment of a regular premium for the term of the plan.',
    level: 0,
  },
  {
    text: `This premium cannot be reviewed by ${insuranceCompanyName} once the policy has been set up and accepted.`,
    level: 0,
  },
  {
    text: 'This policy never acquires a cash value and is not appropriate for retirement funding or savings purposes.',
    level: 0,
  },
  {
    text: `As this is a protection plan, if the premiums cease to be paid, no encashment value will be paid, and the policy will be terminated, after which ${insuranceCompanyName} will have no further liability under this policy.`,
    level: 0,
  },
  {
    text: 'Any failure to disclose material/medical information may invalidate your claim and render you policy void. You should note there is no value payable on this policy at any time',
    level: 0,
  },
];
