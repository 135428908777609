import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ClientForm } from '../../../../components/ClientForm/ClientForm';
import { FormLabel } from '../../../../components/FormLabel/FormLabel';
import { NumberSlider } from '../../../../components/NumberSlider/NumberSlider';
import Theme from '../../../../constants/theme';
import { UserInput } from '../../../../components/UserInput/UserInput';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      textAlign: 'center;',
      paddingTop: '16px',
      width: '100%',
      color: Theme.palette.primary.main,
    },
    grid: {
      width: '100%',
    },
  })
);

export const Clients = ({
  formControl,
  errors,
  watch,
  setValue,
  showCompanyName = false,
  showLifeOfAnotherInput = false,
}) => {
  const basis = watch('basis');
  const classes = useStyles();

  const determineHowManyClientsToShow = () => {
    if (basis) {
      return (
        basis.toLowerCase().includes('dual') ||
        basis.toLowerCase().includes('joint')
      );
    }
    return null;
  };

  const onDependantChange = (value) => {
    setValue('numberOfDependants', value);
  };

  useEffect(() => {
    if (!showCompanyName) {
      setValue('clientCompanyName', '');
    }
    if (!showLifeOfAnotherInput) {
      setValue('lifeOfAnotherInput', '');
    }
  }, [showCompanyName, showLifeOfAnotherInput]);

  const showTwoClients = determineHowManyClientsToShow();
  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Client Details
      </Typography>
      <ClientForm
        formControl={formControl}
        fieldName="Client 1"
        radioGroupName="client1Smoker"
        firstNameInputName="client1FirstName"
        maritalStatusInput="client1MaritalStatus"
        lastNameInputName="client1LastName"
        errors={errors}
        showMartialStatus
        showSmoker
      />
      {showTwoClients && (
        <ClientForm
          formControl={formControl}
          fieldName="Client 2"
          radioGroupName="client2Smoker"
          maritalStatusInput="client2MaritalStatus"
          firstNameInputName="client2FirstName"
          lastNameInputName="client2LastName"
          errors={errors}
          showMartialStatus
          showSmoker
        />
      )}
      <>
        <FormLabel label="Number of dependants" />
        <NumberSlider
          formControl={formControl}
          sliderName="numberOfDependants"
          valuetext="Number of dependants"
          onChange={onDependantChange}
          errors={errors}
        />
      </>
      {showCompanyName && (
        <UserInput
          inputName="clientCompanyName"
          formControl={formControl}
          placeholder="Client Company Name"
          autoComplete="on"
        />
      )}
      {showLifeOfAnotherInput && (
        <UserInput
          inputName="lifeOfAnotherInput"
          formControl={formControl}
          placeholder="Policy Owner"
          autoComplete="off"
        />
      )}
    </>
  );
};

Clients.propTypes = {
  formControl: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  showCompanyName: PropTypes.bool,
  showLifeOfAnotherInput: PropTypes.bool,
};
