import {
  removeSpace,
  removeSpecialCharacters,
} from '../../../../../utils/utils';

export const GREETING =
  'I refer to our recent meeting(s), thank you for taking the time to meet with me to discuss your financial planning requirements. Following an analysis of your financial needs and requirements and based on the information with which you have provided me, we have identified that you have/require the following:';

export const personalCircumstances = ({
  clientCompanyName,
  employerContribution,
  employeeContribution,
}) => {
  const circumstances = [
    {
      text: `You are an employee of ${clientCompanyName} from which you receive a salary and your employer wishes to provide pension benefits.`,
      level: 0,
    },
    {
      text: 'Your employer wants to make pension contributions to ensure you have an appropriate income in place when you retire.',
      level: 0,
    },
    {
      text: `Your employer wishes to make a contribution of ${employerContribution} per month into a pension arrangement on your behalf.`,
      level: 0,
    },
    { text: 'You are in good health.', level: 0 },
  ];

  if (employeeContribution) {
    circumstances.splice(3, 0, {
      text: `You wish to make a contribution of ${employeeContribution} per month into a pension.`,
      level: 0,
    });
  }
  return circumstances;
};

export const needsAndObjectives = [
  {
    text: `You wish to maximise your pension benefits as far as you can afford.`,
    level: 0,
  },
  {
    text: 'Based on your current retirement provision I have determined that you will have an income shortfall at your retirement age.',
    level: 0,
  },
  { text: 'You are looking for a return in excess of inflation.', level: 0 },
  {
    text: 'You want to maximise the tax reliefs that are available.',
    level: 0,
  },
  {
    text: 'You are happy to take risk with your retirement plan in order to get a better potential return than deposits.',
    level: 0,
  },
];

export const financialSituation = ({ salary, employeeContribution }) => {
  const situation = [
    {
      text: `Your salary is ${salary} per annum, as per your payslip.`,
      level: 0,
    },
    { text: 'You have earnings for pension purposes.', level: 0 },
    {
      text: 'We have assessed your need for emergency capital and you are comfortable your circumstances are covered.',
      level: 0,
    },
    {
      text: 'We have captured all this and more in the fact find, a copy of which is enclosed.',
      level: 0,
    },
  ];

  if (!employeeContribution) {
    situation.splice(3, 0, {
      text: 'Affordability is not an issue as you are not making personal contributions; the contributions are 100% employer paid.',
      level: 0,
    });
  }

  return situation;
};

const recommendedProductProviderZurich = ({ amc, trail, allocationRate }) => {
  const totalPercentage = parseFloat(amc) + parseFloat(trail);

  return [
    {
      text: 'Based on the companies which we are authorised to advise on and through our market research we recommend that you effect a retirement plan with Zurich Life.',
      level: 0,
    },
    {
      text: "Zurich Life is one of Ireland's largest Pension, Investment and Protection Providers.",
      level: 0,
    },
    {
      text: `The Zurich Life Retail Master Trust product has a clear charging structure. There is a ${amc}% annual fund management charge (AMC) and a pension authority fee of €6.00 per month. The broker ongoing charge is an additional ${trail}% per annum. This brings the overall base AMC to ${totalPercentage}%`,
      level: 0,
    },
    {
      text:
        `${allocationRate}% of your regular contribution is invested in your fund after broker’s\n` +
        'charges.',
      level: 0,
    },
    {
      text: 'There are no early encashment penalties if fully encashed/transferred.',
      level: 0,
    },
    {
      text: "This plan has access to Zurich Life's wide range of funds which gives you access to many different types of assets classes, sectors and geographies. You can spread your contribution over a number of these funds.",
      level: 0,
    },

    { text: 'Zurich Life allows you to switch between funds.', level: 0 },
    {
      text: "Zurich Life's range of funds allows you to decide upon your own personal reward-risk profile and these funds allow you to match your attitude to Risk/Return with your chosen fund(s).",
      level: 0,
    },
  ];
};

const recommendedProductAvivaLife = ({ amc, trail, allocationRate }) => {
  const totalPercentage = parseFloat(amc) + parseFloat(trail);

  return [
    {
      text: 'Based on the companies which we are authorised to advise on and through our market research we recommend that you effect a retirement plan with Aviva Life.',
      level: 0,
    },
    {
      text: `The Aviva Life Retail Master Trust product has a clear charging structure. There is a ${amc}% annual fund management charge (AMC), a €4.50 per month plan fee and a pension authority fee of €6.00 per month. The broker ongoing charge is an additional ${trail}% per annum. This brings the overall base AMC to ${totalPercentage}%.`,
      level: 0,
    },
    {
      text: `${allocationRate}% of your regular contribution is invested in your fund.`,
      level: 0,
    },
    {
      text: 'There are no early encashment penalties if fully encashed/transferred.',
      level: 0,
    },
    {
      text: 'This plan has access to Aviva Life’s wide range of funds which gives you access to many different types of assets classes, sectors and geographies. You can spread your contributions over a number of these funds.',
      level: 0,
    },
    {
      text: 'Aviva Life allows you to switch between funds free of charge.',
      level: 0,
    },
    {
      text: 'Aviva gives you access to invest through their SDIO product. SDIO is a pension fund that puts you in control of how and where your pension is managed and invested. SDIO is not a standalone or wrapped investment option. It is available as part of one policy. The policy is provided by Aviva and Cantor Fitzgerald is the appointed fund administrator for SDIO. If you want to be more handson with your pension, the SDIO fund lets you tailor your pension fund to your own investment goals and objectives. SDIO offers you access to a range of global investments from specialist product providers and investments like equities and Exchange Traded Funds (ETFs). SDIO gives you the power to give instructions to buy and sell investments with your own pension fund money.',
      level: 0,
    },
    {
      text: 'Aviva Life’s range of funds allows you to decide upon your own personal reward-risk profile and these funds allow you to match your attitude to Risk/Return with your chosen fund(s).',
      level: 0,
    },
  ];
};

const recommendedProductProviderNewIreland = ({
  amc,
  trail,
  allocationRate,
}) => {
  const totalPercentage = parseFloat(amc) + parseFloat(trail);

  return [
    {
      text: 'Based on the companies which we are authorised to advise on and through our market research we recommend that you effect a retirement plan with New Ireland.',
      level: 0,
    },
    {
      text: `The New Ireland Retail Master Trust product has a clear charging structure. There is a ${amc}% annual fund management charge (AMC) and a pension authority fee of €6.00 per month. The broker ongoing charge is an additional ${trail}% per annum. This brings the overall base AMC to ${totalPercentage}%.`,
      level: 0,
    },
    {
      text: `${allocationRate}% of your regular contribution is invested in your fund.`,
      level: 0,
    },
    {
      text: 'There are no early encashment penalties if fully encashed/transferred.',
      level: 0,
    },
    {
      text: 'This plan has access to New Ireland’s wide range of funds which gives you access to many different types of assets classes, sectors and geographies. You can spread your contributions over a number of these funds.',
      level: 0,
    },

    {
      text: 'New Ireland allows you to switch between funds for free for the first 1 per year. Subsequent switches is €25 each.',
      level: 0,
    },
    {
      text: 'New Ireland range of funds allows you to decide upon your own personal reward-risk profile and these funds allow you to match your attitude to Risk/Return with your chosen fund(s).',
      level: 0,
    },
  ];
};

const recommendedProductProviderIrishLife = ({
  amc,
  trail,
  allocationRate,
}) => {
  const totalPercentage = parseFloat(amc) + parseFloat(trail);

  return [
    {
      text: 'Based on the companies which we are authorised to advise on and through our market research we recommend that you effect a retirement plan with Irish Life.',
      level: 0,
    },
    {
      text: `The Irish Life Retail Master Trust product has a clear charging structure. There is a ${amc}% annual fund management charge (AMC), a €4.75 per month plan fee and a pension authority fee of €6.00 per month. The broker ongoing charge is an additional ${trail}% per annum. This brings the overall base AMC to ${totalPercentage}%.`,
      level: 0,
    },
    {
      text: `${allocationRate}% of your regular contribution is invested in your fund.`,
      level: 0,
    },
    {
      text: 'There are no early encashment penalties if fully encashed/transferred.',
      level: 0,
    },
    {
      text: "This plan has access to Irish Life's wide range of funds which gives you access to many different types of assets classes, sectors and geographies. You can spread your contributions over a number of these funds.",
      level: 0,
    },
    {
      text: 'Irish Life allows you to switch between funds free of charges.',
      level: 0,
    },
    {
      text: 'Irish Life range of funds allows you to decide upon your own personal reward-risk profile and these funds allow you to match your attitude to Risk/Return with your chosen fund(s).',
      level: 0,
    },
  ];
};

const recommendedProductProviderStandardLife = ({
  amc,
  trail,
  allocationRate,
}) => {
  const totalPercentage = parseFloat(amc) + parseFloat(trail);

  return [
    {
      text: 'Based on the companies which we are authorised to advise on and through our market research we recommend that you effect a retirement plan with Standard Life.',
      level: 0,
    },
    {
      text: `The Standard Life Retail Master Trust product has a clear charging structure. There is a ${amc}% annual fund management charge (AMC), a €5.42 per month plan fee and a pension authority fee of €6.00 per month. The broker ongoing charge is an additional ${trail}% per annum. This brings the overall base AMC to${totalPercentage}%.`,
      level: 0,
    },
    {
      text: `${allocationRate}% of your regular contribution is invested in your fund.`,
      level: 0,
    },
    {
      text: 'There are no early encashment penalties if fully encashed/transferred.',
      level: 0,
    },
    {
      text: "This plan has access to Standard Life's wide range of funds which gives you access to many different types of assets classes, sectors and geographies. You can spread your contributions over a number of these funds.",
      level: 0,
    },
    {
      text: 'Standard Life has access to low cost index funds such as the Vanguard fund range.',
      level: 0,
    },
    {
      text: 'Standard Life allows you to switch between funds for free for the first 12 per year. Subsequent switches is €60 each.',
      level: 0,
    },
    {
      text: 'Standard Life range of funds allows you to decide upon your own personal reward-risk profile and these funds allow you to match your attitude to Risk/Return with your chosen fund(s).',
      level: 0,
    },
  ];
};

export const recommendedProductProvider = ({ companyName, ...rest }) => {
  const ProviderMap = {
    ZurichLife: recommendedProductProviderZurich({
      ...rest,
    }),
    AvivaLifePensions: recommendedProductAvivaLife({
      ...rest,
    }),
    NewIreland: recommendedProductProviderNewIreland({
      ...rest,
    }),
    IrishLife: recommendedProductProviderIrishLife({
      ...rest,
    }),
    StandardLife: recommendedProductProviderStandardLife({
      ...rest,
    }),
  };

  return ProviderMap[removeSpace(removeSpecialCharacters(companyName))];
};
