import {
  boldTextRun,
  generateBulletPoints,
  paragraphWithTextRun,
  textRun,
} from '../../../wordDoc.utils';
import { declarations } from '../../data/insuranceDocument';

export const DeclarationsLiabilities = () => [
  paragraphWithTextRun({
    children: [
      textRun({
        text:
          'This document does not constitute an offer of contract. Full details of your plan will be available when\n' +
          'the contract is issued.',
        lineBreak: 1,
      }),
    ],
  }),
  paragraphWithTextRun({
    children: [
      textRun({
        text: 'It is very important that you disclose all material facts to us, including those relating to your health.',
        lineBreak: 1,
      }),
    ],
  }),
  paragraphWithTextRun({
    children: [
      textRun({
        text: 'Please read the Guarantees and Limitations section.',
        lineBreak: 1,
      }),
    ],
  }),
  paragraphWithTextRun({
    children: [
      textRun({
        text:
          'I have provided you with a Key Features document which explains how this mortgage protection\n' +
          'policy works in more detail. Please read it carefully and make sure you understand the benefits\n' +
          'provided by the policy. Should you have any further queries or require any further advice in relation to\n' +
          'our discussion or any other financial planning needs, please do not hesitate to contact me at any time.',
        lineBreak: 1,
      }),
    ],
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Declarations: ',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...generateBulletPoints({
    bulletArray: declarations,
  }),
  paragraphWithTextRun({
    children: [
      textRun({
        text:
          'I wish to advise that I have discussed the above in detail with my client and specifically drew their\n' +
          'attention to the terms of the policy. ',
        lineBreak: 1,
      }),
    ],
  }),
];
