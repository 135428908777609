// Inouts
export const PRODUCT_TYPE = 'productType';
export const PREMIUM_TYPE = 'premiumType';
export const COVER_TYPE = 'coverType';
export const COMMISSION_STRUCTURES = 'commissionStructures';
export const INSURANCE_PROVIDERS = 'insuranceProviders';
export const SALARY = 'salary';

// Protection Types
export const TERM_PROTECTION = 'Term Protection';
export const LEVEL_TERM = 'Level Term';
export const MORTGAGE_PROTECTION = 'Mortgage Protection';
export const INCOME_PROTECTION = 'Income Protection';
export const WHOLE_OF_LIFE = 'Whole of Life';

// Pension Types
export const EXECUTIVE_PENSION = 'Executive';

// Basis
export const EXECUTIVE = 'Executive';
export const PERSONAL = 'Personal';
export const CONVERTIBLE_TERM = 'Convertible Term';

// Statement of Suitability
export const TYPE = 'type';
export const PROTECTION = 'Protection';
export const PENSIONS = 'Pensions';

// Insurance Providers
export const HASTINGS = 'Hastings(Westport) Ltd';
export const DAVID_FLANNELLY = 'David Flannellys(Holdings) Ltd';
export const PAT_HARDIMAN = 'Pat Hardiman Insurances Ltd';
export const HASTINGS_LIFE_PENSIONS = 'Hastings Life & Pensions Ltd';

export const ACCELERATED = 'Accelerated';
export const STANDALONE = 'Standalone';

export const LIFE_OF_ANOTHER = 'Life of another';

export const SINGLE = 'Single';
export const MARRIED = 'Married';
export const DIVORCED = 'Divorced';
export const SEPARATED = 'Separated';
export const ENGAGED = 'Engaged';
export const WIDOWED = 'Widowed';

export const DUAL = 'Dual';
export const JOINT = 'Joint';
export const NONE = 'None';

export const SINGLE_LIFE = 'Single Life';
export const JOINT_LIFE_FIRST_DEATH = 'Joint Life 1st Death';
export const JOINT_LIFE_SECOND_DEATH = 'Joint Life 2nd Death';
export const DUAL_LIFE = 'Dual Life';
