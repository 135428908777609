import React from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Theme from './constants/theme';
import { HastingsRouter } from './router/HastingsRouter';

function App() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <ThemeProvider theme={Theme}>
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement="bottom-right"
      >
        <Router>
          <HastingsRouter />
        </Router>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;
