import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { NavBar } from '../components/NavBar/NavBar';
import { StatementOfSuitability } from '../pages/StatementOfSuitability/StatementOfSuitability';
import { Landing } from '../pages/Landing/Landing';
import { Login } from '../pages/Login/Login';
import { ProtectedRoute } from './ProtectedRoute/ProtectedRoute';
import { ChangePassword } from '../pages/ChangePassword/ChangePassword';
import { AccountProvider } from '../providers/AccountProvider/AccountProvider';
import { CommissionStructures } from '../pages/CommissionStructures/CommissionStructures';

export const HastingsRouter = () => (
  <AccountProvider>
    <NavBar />
    <Route exact path="/">
      <Redirect to="/Dashboard" />
    </Route>
    <Route path="/Dashboard">
      <ProtectedRoute component={<Landing />} />
    </Route>
    <Route path="/Login" render={() => <Login />} />
    <Route path="/ChangePassword" render={() => <ChangePassword />} />
    <Route path="/StatementOfSuitability">
      <ProtectedRoute component={<StatementOfSuitability />} />
    </Route>
    <Route path="/CommissionStructures">
      <ProtectedRoute component={<CommissionStructures />} />
    </Route>
  </AccountProvider>
);
