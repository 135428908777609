import { saveAs } from 'file-saver';
import { Document, Packer } from 'docx';
import { styles, numbering } from '../../wordDoc.styles';
import { HastingsFooter } from '../../components/Footer/Footer';
import {
  getAge,
  removeSpace,
  removeSpecialCharacters,
} from '../../../../utils/utils';
import { page1 } from './page1';
import { page2 } from './page2';
import { page3 } from './page3';
import { page4 } from './page4';
import { filterStatements } from '../utils/pensionUtils';
import { defaultDynamicStatements } from './data/executivePage2';
import { HastingsFinancialHeader } from '../Header/Header';

export const generateRetailMasterTrust = ({
  clientFirstName = '',
  clientLastName = '',
  hastingsCompany = '',
  financialAdvisor = '',
  companyName = '',
  employeeContribution = 0,
  employerContribution = 0,
  clientCompanyName = '',
  retirementAge = 65,
  client1DOB = '',
  salary = 0,
  amc = 0,
  allocationRate = 0,
  trail = 0,
  riskProfile = 0,
  dynamicStatements,
  ESG,
  riskProfileMatch,
}) => {
  const clientFullName = `${clientFirstName} ${clientLastName}`;
  const age = getAge(client1DOB);

  const filteredStatements = filterStatements({
    statements: dynamicStatements,
    ESG,
    insuranceProvider: removeSpace(removeSpecialCharacters(companyName)),
    type: 'Executive',
    riskProfile: removeSpace(riskProfile),
  });

  const defaultAndDynamic = [
    ...filteredStatements[0].statements,
    ...defaultDynamicStatements({ riskProfile }),
  ];

  const doc = new Document({
    creator: 'Hastings',
    title: `${clientFullName} statement of suitability.`,
    description: `Recommendation: ${companyName} `,
    styles,
    numbering,
    sections: [
      {
        headers: HastingsFinancialHeader,
        footers: HastingsFooter({ hastingsCompany }),
        children: [
          ...page1({
            financialAdvisor,
            clientFullName,
            retirementAge,
            clientCompanyName,
            age,
            clientFirstName,
            companyName,
            employeeContribution,
            employerContribution,
            salary,
          }),
          ...page2({ companyName, amc, allocationRate, trail, riskProfile }),
          ...page3({
            financialAdvisor,
            ESG,
            riskProfileMatch,
            dynamicStatements: defaultAndDynamic.map((statement) => ({
              text: statement,
              level: 0,
            })),
          }),
          ...page4({ clientName: clientFullName, clientCompanyName }),
        ],
      },
    ],
  });

  return Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${clientFullName} Statement of Suitability.docx`);
  });
};
