import {
  ACCELERATED,
  CONVERTIBLE_TERM,
  DAVID_FLANNELLY,
  DIVORCED,
  DUAL,
  DUAL_LIFE,
  ENGAGED,
  EXECUTIVE,
  HASTINGS,
  INCOME_PROTECTION,
  JOINT,
  JOINT_LIFE_FIRST_DEATH,
  JOINT_LIFE_SECOND_DEATH,
  LEVEL_TERM,
  LIFE_OF_ANOTHER,
  MARRIED,
  MORTGAGE_PROTECTION,
  NONE,
  PAT_HARDIMAN,
  PENSIONS,
  PERSONAL,
  PROTECTION,
  SEPARATED,
  SINGLE,
  SINGLE_LIFE,
  STANDALONE,
  HASTINGS_LIFE_PENSIONS,
  TERM_PROTECTION,
  WHOLE_OF_LIFE,
  WIDOWED,
} from '../constants/form';

export const INSURANCE_COMPANIES = {
  TermProtection: [
    { value: 'Aviva Life & Pensions', label: 'Aviva Life' },
    { value: 'New Ireland', label: 'New Ireland' },
    { value: 'Irish Life', label: 'Irish Life' },
    { value: 'Zurich Life', label: 'Zurich Life' },
    { value: 'Royal London', label: 'Royal London' },
  ],
  IncomeProtection: [
    { value: 'Aviva Life & Pensions', label: 'Aviva Life' },
    { value: 'New Ireland', label: 'New Ireland' },
    { value: 'Irish Life', label: 'Irish Life' },
    { value: 'Royal London', label: 'Royal London' },
  ],
  WholeofLife: [
    { value: 'Irish Life', label: 'Irish Life' },
    { value: 'Zurich Life', label: 'Zurich Life' },
    { value: 'Royal London', label: 'Royal London' },
  ],
};

export const YES_NO = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const SMOKER = [
  { value: 'Non-Smoker', label: 'Non-Smoker' },
  { value: 'Smoker', label: 'Smoker' },
];

export const INSURANCE_TYPE = [
  {
    value: MORTGAGE_PROTECTION,
    label: MORTGAGE_PROTECTION,
  },
  { value: LEVEL_TERM, label: LEVEL_TERM },
  { value: CONVERTIBLE_TERM, label: CONVERTIBLE_TERM },
];

export const BASIS = {
  TermProtection: [
    { value: JOINT, label: JOINT },
    { value: DUAL, label: DUAL },
    { value: SINGLE, label: SINGLE },
    { value: LIFE_OF_ANOTHER, label: LIFE_OF_ANOTHER },
  ],
  IncomeProtection: [
    { value: PERSONAL, label: PERSONAL },
    { value: EXECUTIVE, label: EXECUTIVE },
  ],
  WholeofLife: [
    { value: SINGLE_LIFE, label: SINGLE_LIFE },
    { value: JOINT_LIFE_FIRST_DEATH, label: JOINT_LIFE_FIRST_DEATH },
    { value: JOINT_LIFE_SECOND_DEATH, label: JOINT_LIFE_SECOND_DEATH },
    { value: DUAL_LIFE, label: DUAL_LIFE },
  ],
};

export const map = { first: '1', second: ['2'] };

export const HASTINGS_COMPANY = [
  { value: HASTINGS, label: 'Westport' },
  { value: DAVID_FLANNELLY, label: 'Castlebar' },
  { value: PAT_HARDIMAN, label: 'Tuam' },
  { value: HASTINGS_LIFE_PENSIONS, label: 'Dublin' },
];

export const SERIOUS_ILLNESS_COVER = [
  { value: STANDALONE, label: STANDALONE },
  { value: ACCELERATED, label: ACCELERATED },
  { value: NONE, label: NONE },
];

export const PRODUCT_TYPE = [
  { value: PROTECTION, label: PROTECTION },
  { value: PENSIONS, label: PENSIONS },
  // { value: 'Savings & Investments', label: 'Savings & Investments' },
];

export const PROTECTION_TYPES = [
  { value: TERM_PROTECTION, label: TERM_PROTECTION },
  { value: INCOME_PROTECTION, label: INCOME_PROTECTION },
  { value: WHOLE_OF_LIFE, label: WHOLE_OF_LIFE },
];

export const MARITAL_STATUS = [
  { value: MARRIED, label: MARRIED },
  { value: SINGLE, label: SINGLE },
  { value: DIVORCED, label: DIVORCED },
  { value: SEPARATED, label: SEPARATED },
  { value: ENGAGED, label: ENGAGED },
  { value: WIDOWED, label: WIDOWED },
];

export const DEFERRED_PERIOD = [
  { value: 4, label: 4 },
  { value: 8, label: 8 },
  { value: 13, label: 13 },
  { value: 26, label: 26 },
  { value: 52, label: 52 },
];
