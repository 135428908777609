import {
  ACCELERATED,
  CONVERTIBLE_TERM,
  LEVEL_TERM,
  MORTGAGE_PROTECTION,
  NONE,
  STANDALONE,
} from '../../../../constants/form';

export const wholeLifeNeedsAndObjectives = ({
  inheritanceTax,
  lifeCoverAmount,
  basis,
}) => {
  const needsObjectives = [
    { text: 'You require whole of life cover.', level: 0 },
    {
      text: `You have specified that you need ${lifeCoverAmount} of ${basis.toLowerCase()} cover`,
      level: 0,
    },
  ];

  if (inheritanceTax) {
    needsObjectives.unshift({
      text: 'You require a life assurance policy to cover the potential cost of inheritance tax for your children.',
      level: 0,
    });
  } else {
    needsObjectives.unshift({
      text: 'You require a life assurance policy to cover the potential cost of funeral expenses.',
      level: 0,
    });
  }

  return needsObjectives;
};

export const incomeProtectionNeedsAndObjectives = ({
  benefitAmount,
  NRA,
  deferredPeriod,
}) => [
  {
    text: 'A monthly income to meet financial obligations if you cannot work due to accident or illness.',
    level: 0,
  },
  {
    text: 'Peace of mind knowing that you will be protected financially should you become sick or injured.',
    level: 0,
  },
  {
    text: 'Monthly payments to meet your monthly expenditure if you are out of work due to ill-health.',
    level: 0,
  },
  {
    text: `You require income protection of ${benefitAmount} per annum to ${NRA}, after allowing for income from any other sources.`,
    level: 0,
  },
  {
    text: `The deferred period you require before the benefit amount is to be paid is ${deferredPeriod} weeks.`,
    level: 0,
  },
  {
    text: 'You only need this cover for a specified number of years.',
    level: 0,
  },
];

export const incomeProtectionExtraNeedsObjectivesParagraph =
  'Income protection provides you with a replacement income if you cannot work as a result of an illness or injury after a certain period of time. You can take out income protection if you are in full-time work or are self-employed and earn an income. It protects you only in these circumstances - it will not be paid if you become unemployed.';

export const incomeProtectionExtraNeedsObjectivesBullets = [
  {
    text: 'The benefits of this type of policy will only be paid after a certain period of illness, known as a deferred period, which can range from 4 to 52 weeks.',
    level: 0,
  },
  {
    text: "There is also a maximum percentage of salary which can be paid during a claim of up to 75% of income less the single person's State Illness Benefit, subject to a cap at higher levels of income.",
    level: 0,
  },
  {
    text: 'Income Protection covers any illness, any injury and any disability. This gives you the complete peace of mind of knowing that no matter what happens, you and your loved ones are protected.',
    level: 0,
  },
];
export const termCoverNeedsAndObjectives = ({
  term = '',
  lifeCoverAmount = 0,
  seriousIllness = 0,
  insuranceType,
}) => {
  const needsObjectives = [
    {
      text: `You would like your cover to last for ${term} years.`,
      level: 0,
    },
  ];

  if (insuranceType === LEVEL_TERM || insuranceType === CONVERTIBLE_TERM) {
    if (seriousIllness && seriousIllness !== NONE) {
      needsObjectives.push({
        text: 'In the event of your premature death and/or the onset of a specified serious illness within the insurance term, you would like to protect yourself and/or provide financial support to your family/dependants.',
        level: 0,
      });
    } else {
      needsObjectives.push({
        text: 'In the event of your premature death, within the insurance term, you would like to protect yourself and/or provide financial support to your family/dependants.',
        level: 0,
      });
    }
  }

  if (insuranceType === MORTGAGE_PROTECTION) {
    needsObjectives.push(
      {
        text:
          'In the event of your premature death, within the insurance term, you would like to cover the\n' +
          'outstanding mortgage on your home.',
        level: 0,
      },
      {
        text: `You would like the cover to tie in with the mortgage amount of ${lifeCoverAmount}.`,
        level: 0,
      },
      {
        text:
          'You require a mortgage protection policy to cover your mortgage as required by your mortgage\n' +
          'lender.',
        level: 0,
      }
    );
  }

  if (seriousIllness === ACCELERATED || seriousIllness === STANDALONE) {
    needsObjectives.splice(3, 0, {
      text: 'You would like to include serious illness cover on this policy',
      level: 0,
    });
  }

  return needsObjectives;
};
