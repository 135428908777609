import React from 'react';
import { Container, Grid, Paper, Zoom } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { LandingCard } from '../../components/LandingCard/LandingCard';
import Theme from '../../constants/theme';
import statementOfSuitability from '../../assets/images/statementOfSuitability.jpeg';
import commission from '../../assets/images/Commission.jpeg';
import hastingsToolkitLogo from '../../assets/images/HastingsToolkit.png';
import { COMMISSION_STRUCTURES } from '../../constants/form';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: Theme.spacing(5),
      paddingBottom: Theme.spacing(5),
    },
    paper: {
      padding: Theme.spacing(5),
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      paddingBottom: Theme.spacing(5),
    },
    image: {
      alignSelf: 'center',
      paddingBottom: Theme.spacing(5),
    },
  })
);

const LandingCards = [
  {
    heading: 'Statement of Suitability',
    description: 'Dynamically generate a Statement of Suitability.',
    link: '/StatementOfSuitability',
    image: statementOfSuitability,
    key: 'StatementOfSuitability',
  },
  {
    heading: 'Commission Structures',
    description: 'Generate commission profiles.',
    link: '/CommissionStructures',
    image: commission,
    key: COMMISSION_STRUCTURES,
  },
];

export const Landing = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper variant="outlined" className={classes.paper} elevation={3}>
        <img
          src={hastingsToolkitLogo}
          alt="hastingsToolkitLogo"
          width={150}
          className={classes.image}
          draggable={false}
        />
        <Grid container alignItems="center" spacing={3}>
          {LandingCards.map((card, index) => (
            <Zoom
              in
              style={{
                transitionDelay: `calc(100ms * ${index})`,
              }}
              key={card.key}
            >
              <Grid item xs={12} md={4} lg={4}>
                <LandingCard
                  heading={card.heading}
                  description={card.description}
                  link={card.link}
                  image={card.image}
                />
              </Grid>
            </Zoom>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};
