import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { MenuItem, Select, Zoom } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';

const useStyles = makeStyles(() =>
  createStyles({
    dropdown: { width: '100%' },
    label: {
      fontSize: '10px',
      paddingTop: '2px',
    },
  })
);

export const SelectInput = ({
  formControl,
  itemArray,
  selectMenuName,
  playTransition = false,
  isRequired = true,
  defaultValue,
  errors,
}) => {
  const classes = useStyles();
  if (!playTransition) {
    return (
      <>
        {/* eslint-disable-next-line no-prototype-builtins */}
        {errors.hasOwnProperty(selectMenuName) && (
          <ErrorMessage message="This field is required." />
        )}
        <Controller
          render={({ field }) => (
            <>
              {' '}
              <Select
                {...field}
                className={classes.dropdown}
                defaultValue=""
                disabled={!itemArray.length}
              >
                {itemArray &&
                  itemArray.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </>
          )}
          rules={{
            required: isRequired,
          }}
          defaultValue={defaultValue}
          name={selectMenuName}
          control={formControl}
        />
      </>
    );
  }
  return (
    <>
      {/* eslint-disable-next-line no-prototype-builtins */}
      {errors.hasOwnProperty(selectMenuName) && (
        <ErrorMessage message="This field is required." />
      )}
      <Controller
        render={({ field }) => (
          <>
            <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
              <Select
                {...field}
                className={classes.dropdown}
                defaultValue=""
                disabled={!itemArray.length}
              >
                {itemArray &&
                  itemArray.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </Zoom>
          </>
        )}
        rules={{
          required: isRequired,
        }}
        defaultValue={defaultValue}
        name={selectMenuName}
        control={formControl}
      />
    </>
  );
};

SelectInput.propTypes = {
  formControl: PropTypes.object,
  itemArray: PropTypes.array,
  selectMenuName: PropTypes.string,
  defaultValue: PropTypes.string,
  selectFieldName: PropTypes.string,
  errors: PropTypes.object,
  isRequired: PropTypes.bool,
  playTransition: PropTypes.bool,
};
