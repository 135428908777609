import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { FormLabel } from '../FormLabel/FormLabel';

const useStyles = makeStyles(() =>
  createStyles({
    datePicker: {
      width: '100%',
    },
  })
);

export const DatePicker = ({
  formControl,
  datePickerName,
  selectFieldName,
  styles,
  defaultValue = '2000-01-01',
  playTransition = true,
}) => {
  const classes = useStyles();
  return (
    <>
      <FormLabel label={selectFieldName} playTransition={playTransition} />
      <Grid item xs={12} spacing={3}>
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              id="date"
              type="date"
              defaultValue={defaultValue}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.datePicker}
              style={styles}
            />
          )}
          defaultValue={defaultValue}
          control={formControl}
          name={datePickerName}
        />
      </Grid>
    </>
  );
};

DatePicker.propTypes = {
  formControl: PropTypes.object,
  datePickerName: PropTypes.string,
  selectFieldName: PropTypes.string,
  styles: PropTypes.object,
  defaultValue: PropTypes.string,
  playTransition: PropTypes.bool,
};
