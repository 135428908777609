import {
  boldTextRun,
  paragraphWithBullet,
  paragraphWithTextRun,
} from '../../../wordDoc.utils';
import { INCOME_PROTECTION, SINGLE } from '../../../../../constants/form';

export const PersonalCircumstances = ({
  clientFirstName = '',
  maritalStatus = SINGLE,
  smoker = 'Non-Smoker',
  lineBreak = 0,
  isTwoClients = false,
  numberOfDependants = 0,
  insuranceType = '',
  protection,
}) => {
  if (!clientFirstName) {
    return [];
  }

  const dependantText = () => {
    if (numberOfDependants === 0) return 'You have no dependants.';
    if (numberOfDependants === 1) return 'You have 1 dependant.';
    return `You have ${numberOfDependants} dependants.`;
  };

  const personalCircumstances = [
    paragraphWithBullet({
      text: `You are ${maritalStatus.toLowerCase()}.`,
    }),
    paragraphWithBullet({
      text: dependantText(),
    }),
    paragraphWithBullet({
      text: `You are a ${smoker?.toLowerCase()}. To be a smoker one must currently smoke, occasionally smoke or have smoked in the last 12 months or have used nicotine replacement products including e-cigarettes in the last 12 months.`,
    }),
  ];

  if (protection === INCOME_PROTECTION) {
    personalCircumstances.push(
      paragraphWithBullet({
        text: `You require ${protection?.toLowerCase()} cover.`,
      })
    );
  } else {
    personalCircumstances.push(
      paragraphWithBullet({
        text: `You require ${insuranceType?.toLowerCase()} cover.`,
      })
    );
  }

  if (isTwoClients) {
    personalCircumstances.unshift(
      paragraphWithTextRun({
        children: [
          boldTextRun({
            text: `${clientFirstName}:`,
            lineBreak,
          }),
        ],
      })
    );
  }

  return personalCircumstances;
};
