import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { populateArray } from './tableRows';

export const PensionSinglePremiumContent = ({
  convertedSinglePremium,
  singlePremiumResult,
  singlePremiumConfig,
  retirementAge,
  age,
}) => {
  const isTooCloseToRetirement =
    retirementAge - age < singlePremiumConfig.minimumTerm;

  if (
    convertedSinglePremium < singlePremiumConfig.minAmount ||
    isTooCloseToRetirement
  ) {
    return <p>{singlePremiumConfig.outsideRangeMessage}</p>;
  }

  if (!singlePremiumResult) {
    return <p>No Pension Commission Structure Available</p>;
  }

  const tableRows = populateArray({ singlePremiumResult });
  return (
    <Table>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={row.key}>
            <TableCell style={{ width: 160 }} align="left">
              {row.label}
            </TableCell>
            <TableCell style={{ width: 160 }} align="left">
              {row.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

PensionSinglePremiumContent.propTypes = {
  convertedSinglePremium: PropTypes.number,
  singlePremiumResult: PropTypes.number,
  age: PropTypes.number,
  retirementAge: PropTypes.number,
  singlePremiumConfig: PropTypes.object,
};
