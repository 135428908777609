import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    nextButton: {
      width: '100%',
      justifyContent: 'flex-end',
      display: 'flex',
    },
  })
);

export const CompleteButton = ({
  activePageIndex,
  pages = [],
  onNextClick,
  disabled,
}) => {
  const classes = useStyles();

  if (activePageIndex === pages.length - 2) {
    return (
      <div className={classes.nextButton}>
        <Fade in>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            onClick={onNextClick}
            style={{ width: '120px' }}
            disabled={disabled}
          >
            Complete
          </Button>
        </Fade>
      </div>
    );
  }
  return null;
};

CompleteButton.propTypes = {
  activePageIndex: PropTypes.number,
  pages: PropTypes.array,
  onNextClick: PropTypes.func,
  disabled: PropTypes.bool,
};
