import { PREMIUM_TYPES } from '../../CommissionStructure.constant';
import { removeSpace } from '../../../../utils/utils';

export const getInsuranceProviderConfig = ({
  commissionStructures,
  insuranceProvider,
  premiumType,
  productType,
}) => {
  const config = commissionStructures.filter(
    (commissionStructure) => commissionStructure.dropdown === 'config'
  );

  return config.find(
    (obj) =>
      obj.insuranceProvider === insuranceProvider &&
      (obj.premiumType === premiumType ||
        obj.premiumType === PREMIUM_TYPES.BOTH) &&
      obj.associatedProducts.includes(productType)
  );
};

export const findPensionResult = ({
  commissionStructures,
  premiumType,
  age,
  premium,
  insuranceProvider,
  productType,
}) =>
  commissionStructures.find(
    (obj) =>
      obj.productType === productType &&
      obj.minAmount <= premium &&
      obj.minAmount !== null &&
      obj.maxAmount >= premium &&
      obj.maxAmount !== null &&
      obj.minAge <= age &&
      obj.minAge !== null &&
      obj.maxAge >= age &&
      obj.maxAge !== null &&
      obj.premiumType === premiumType &&
      obj.insuranceProvider === insuranceProvider
  );

export const findSavingsInvestmentResult = ({
  commissionStructures,
  premiumType,
  coverType,
  premium,
  insuranceProvider,
  productType,
}) => {
  const savingsInvestment = commissionStructures.filter(
    (commissionStructure) => commissionStructure.coverType === coverType
  );

  return savingsInvestment.find(
    (obj) =>
      removeSpace(obj.productType).toLowerCase() ===
        removeSpace(productType).toLowerCase() &&
      removeSpace(obj.premiumType).toLowerCase() ===
        removeSpace(premiumType).toLowerCase() &&
      obj.insuranceProvider === insuranceProvider &&
      obj.minAmount <= premium &&
      obj.minAmount !== null &&
      obj.maxAmount >= premium &&
      obj.maxAmount !== null &&
      obj.insuranceProvider === insuranceProvider
  );
};

export const findProtectionResult = ({
  commissionStructures,
  insuranceProvider,
  coverType,
  productType,
}) => {
  const protection = commissionStructures.filter(
    (commissionStructure) => commissionStructure.coverType === coverType
  );
  return protection.find(
    (obj) =>
      removeSpace(obj.productType).toLowerCase() ===
        removeSpace(productType).toLowerCase() &&
      removeSpace(obj.insuranceProvider).toLowerCase() ===
        removeSpace(insuranceProvider).toLowerCase()
  );
};
