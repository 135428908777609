export const GUARANTEES_LIMITATIONS = [
  {
    text: 'The investment is not guaranteed to return your capital or achieve any rate of return.',
    level: 0,
  },
  {
    text: 'There is no guarantee that the return on this pension policy will match or beat inflation.',
    level: 0,
  },
  {
    text: 'There is no guarantee that this contract will provide enough to pay any particular income in retirement.',
    level: 0,
  },
  {
    text: 'Tax relief and benefit options can be subject to amendment by governments.',
    level: 0,
  },
  {
    text: 'You will not have access to this retirement plan until you reach your Normal Retirement Age unless you qualify for early retirement drawdown on leaving service prior to your Normal Retirement Age or in the event that you become seriously ill.',
    level: 0,
  },
  {
    text: 'You may have to wait for an extended period in order to encash any investments in property.',
    level: 0,
  },
  {
    text: 'There are early encashment penalties if fully encashed/transferred before 5 years. Please see penalties noted above.',
    level: 0,
  },
];

export const GUARANTEES_LIMITATIONS_SECTION =
  'I have provided you with a Customer Guide and a copy of your Policy Document that explains how this policy works in more detail. Please read them carefully and make sure you understand the benefits provided by the policy.';

export const riskProfileMatchESG = ({ ESG, riskProfileMatch }) => {
  const riskProfileMatchESGArray = [];

  if (ESG) {
    riskProfileMatchESGArray.push({
      text: 'ESG Accepted',
      level: 0,
    });
  }

  if (!ESG) {
    riskProfileMatchESGArray.push({
      text: 'ESG Not Accepted',
      level: 0,
    });
  }

  if (riskProfileMatch) {
    riskProfileMatchESGArray.push({
      text: 'Risk Profile Match Accepted',
      level: 0,
    });
  }

  if (!riskProfileMatch) {
    riskProfileMatchESGArray.push({
      text: 'Risk Profile Match Not Accepted',
      level: 0,
    });
  }

  return riskProfileMatchESGArray;
};
