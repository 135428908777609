import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { MARITAL_STATUS, SMOKER } from '../../data/LifeInsurance';
import { DatePicker } from '../DatePicker/DatePicker';
import { UserInput } from '../UserInput/UserInput';
import { FormLabel } from '../FormLabel/FormLabel';
import { RadioButton } from '../RadioButton/RadioButton';
import { SelectMenu } from '../SelectMenu/SelectMenu';
import { FormattedNumberInput } from '../FormattedNumberInput/FormattedNumberInput';
import { SALARY } from '../../constants/form';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '16px',
      marginBottom: '16px',
    },
    maritalStatus: {
      marginTop: '8px',
      alignSelf: 'center',
    },
    label: {
      fontSize: '16px',
      paddingTop: '8px',
    },
    radio: { paddingTop: '8px', paddingBottom: '8px' },
    wrapper: {
      paddingBottom: '16px',
      width: '100%',
    },
  })
);

export const ClientForm = ({
  formControl,
  fieldName = 'Client Details',
  firstNameInputName = 'clientFirstName',
  lastNameInputName = 'clientLastName',
  maritalStatusInput = 'clientMartialStatus',
  radioGroupName = 'clientSmokerStatus',
  defaultSalary = null,
  showDOB = false,
  showSmoker = false,
  showMartialStatus = false,
  showSalary = false,
  errors,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <FormLabel
          label={fieldName}
          styles={{ paddingTop: '18px', fontSize: '18px' }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <UserInput
          formControl={formControl}
          inputName={firstNameInputName}
          placeholder="First Name"
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <UserInput
          formControl={formControl}
          inputName={lastNameInputName}
          placeholder="Last Name"
        />
      </Grid>
      {showMartialStatus && (
        <Grid item xs={12} md={5} className={classes.maritalStatus}>
          <SelectMenu
            formControl={formControl}
            selectMenuName={maritalStatusInput}
            itemArray={MARITAL_STATUS}
            selectFieldName="Marital Status"
            errors={errors}
          />
        </Grid>
      )}
      {showDOB && (
        <Grid item xs={12} md={5}>
          <DatePicker
            formControl={formControl}
            datePickerName="client1DOB"
            selectFieldName="DOB"
          />
        </Grid>
      )}
      {showSmoker && (
        <Grid
          item
          xs={12}
          md={5}
          justifyContent="center"
          className={classes.radio}
        >
          <RadioButton
            row
            formControl={formControl}
            radioGroupName={radioGroupName}
            radioOptions={SMOKER}
          />
        </Grid>
      )}
      {showSalary && (
        <Grid item xs={12} md={5}>
          <FormattedNumberInput
            playTransition
            prefix="€"
            inputName={SALARY}
            errors={errors}
            formControl={formControl}
            isRequired
            defaultValue={defaultSalary}
            label="€ Salary"
          />
        </Grid>
      )}
    </>
  );
};

ClientForm.propTypes = {
  formControl: PropTypes.object,
  fieldName: PropTypes.string,
  defaultSalary: PropTypes.string,
  maritalStatusInput: PropTypes.string,
  firstNameInputName: PropTypes.string,
  lastNameInputName: PropTypes.string,
  radioGroupName: PropTypes.string,
  showDOB: PropTypes.bool,
  errors: PropTypes.object,
  showMartialStatus: PropTypes.bool,
  showSmoker: PropTypes.bool,
  showSalary: PropTypes.bool,
};
