import React from 'react';
import PropTypes from 'prop-types';
import { Zoom } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { FormLabel } from '../FormLabel/FormLabel';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

export const FormattedNumberInput = ({
  formControl,
  inputName,
  prefix,
  suffix,
  defaultValue = 2000,
  label,
  isRequired = true,
  playTransition = true,
  errors,
}) => {
  if (!playTransition) {
    return (
      <>
        <FormLabel label={label} playTransition={playTransition} />
        <Controller
          render={({ field }) => (
            <>
              <NumberFormat
                {...field}
                style={{ width: '100%' }}
                customInput={TextField}
                placeholder={label}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                autoComplete="off"
              />
              {/* eslint-disable-next-line no-prototype-builtins */}
              {errors.hasOwnProperty(inputName) && (
                <ErrorMessage message="This field is required." />
              )}
            </>
          )}
          rules={{ required: isRequired }}
          name={inputName}
          variant="outlined"
          defaultValue={defaultValue}
          control={formControl}
        />
      </>
    );
  }

  return (
    <>
      <FormLabel label={label} playTransition={playTransition} />
      <Controller
        render={({ field }) => (
          <>
            <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
              <NumberFormat
                {...field}
                style={{ width: '100%' }}
                customInput={TextField}
                placeholder={label}
                thousandSeparator
                prefix={prefix}
                autoComplete="off"
              />
            </Zoom>
            {/* eslint-disable-next-line no-prototype-builtins */}
            {errors.hasOwnProperty(inputName) && (
              <ErrorMessage message="This field is required." />
            )}
          </>
        )}
        rules={{ required: isRequired }}
        name={inputName}
        variant="outlined"
        defaultValue={defaultValue}
        control={formControl}
      />
    </>
  );
};

FormattedNumberInput.propTypes = {
  formControl: PropTypes.object,
  errors: PropTypes.object,
  inputName: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.number,
  playTransition: PropTypes.bool,
  isRequired: PropTypes.bool,
};
