import {
  boldTextRun,
  generateBulletPoints,
  paragraphWithTextRun,
  textRun,
} from '../../wordDoc.utils';
import { attitudeToRisk, RECOMMENDED_PRODUCT } from './data/executivePage2';
import { recommendedProductProvider } from './data/executivePage1';

export const page2 = ({
  companyName,
  amc,
  allocationRate,
  trail,
  riskProfile,
}) => [
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Recommended Product: Retail Master Trust Plan:',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...generateBulletPoints({
    bulletArray: RECOMMENDED_PRODUCT,
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: `Our Recommended Product Provider: ${companyName}`,
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...generateBulletPoints({
    bulletArray: recommendedProductProvider({
      companyName,
      amc,
      allocationRate,
      trail,
    }),
  }),
  paragraphWithTextRun({
    children: [
      boldTextRun({
        text: 'Risk profile of this product and your Attitude to Risk:',
        lineBreak: 1,
      }),
    ],
    spacingAfter: 0.1,
  }),
  ...attitudeToRisk({ riskProfile }).map((riskStatement) =>
    paragraphWithTextRun({
      children: [
        textRun({
          text: riskStatement.text,
        }),
      ],
      spacingAfter: 0.1,
    })
  ),
];
