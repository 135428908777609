import { convertInchesToTwip, Header, ImageRun, Paragraph } from 'docx';
import { HastingsFinancialBase64 } from '../assets/HastingsFinancialBase64';

const HastingsFinancialHeaderImage = new ImageRun({
  data: HastingsFinancialBase64,
  transformation: {
    width: 170,
    height: 55,
  },
  floating: {
    horizontalPosition: {
      offset: convertInchesToTwip(3500),
    },
    verticalPosition: {
      offset: convertInchesToTwip(100),
    },
    margins: {
      top: 100,
      left: 1000,
      right: 100,
    },
  },
});

export const HastingsFinancialHeader = {
  default: new Header({
    children: [
      new Paragraph({
        children: [HastingsFinancialHeaderImage],
      }),
    ],
  }),
};
