import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import {
  BASIS,
  INSURANCE_COMPANIES,
  INSURANCE_TYPE,
  SERIOUS_ILLNESS_COVER,
} from '../../../../data/LifeInsurance';
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu';
import { FormattedNumberInput } from '../../../../components/FormattedNumberInput/FormattedNumberInput';
import { NumberSlider } from '../../../../components/NumberSlider/NumberSlider';
import { FormLabel } from '../../../../components/FormLabel/FormLabel';
import { removeSpace } from '../../../../utils/utils';
import { Clients } from '../Clients/Clients';
import { LIFE_OF_ANOTHER, NONE, TYPE } from '../../../../constants/form';

export const TermProtectionInsurance = ({
  formControl,
  watch,
  errors,
  setValue,
}) => {
  const companyName = watch('companyName');
  const protection = watch(TYPE);
  const seriousIllness = watch('seriousIllness');
  const insuranceType = watch('insuranceType');
  const basis = watch('basis');

  const onTermChange = (value) => {
    setValue('term', value);
  };

  return (
    <>
      <SelectMenu
        formControl={formControl}
        selectMenuName="insuranceType"
        itemArray={INSURANCE_TYPE}
        selectFieldName="Insurance Type"
        errors={errors}
      />
      {insuranceType && (
        <SelectMenu
          errors={errors}
          formControl={formControl}
          selectMenuName="basis"
          itemArray={BASIS[removeSpace(protection)]}
          selectFieldName="Basis"
        />
      )}
      {basis && (
        <SelectMenu
          formControl={formControl}
          selectMenuName="companyName"
          itemArray={INSURANCE_COMPANIES[removeSpace(protection)]}
          selectFieldName="Company Name"
          errors={errors}
        />
      )}
      {companyName && (
        <SelectMenu
          formControl={formControl}
          selectMenuName="seriousIllness"
          itemArray={SERIOUS_ILLNESS_COVER}
          selectFieldName="Serious Illness"
          errors={errors}
        />
      )}
      {seriousIllness && (
        <>
          <FormLabel label="Term" />
          <NumberSlider
            formControl={formControl}
            min={0}
            max={40}
            defaultValue={10}
            sliderName="term"
            valuetext="Term"
            onChange={onTermChange}
            errors={errors}
          />
        </>
      )}
      {seriousIllness && (
        <>
          <Grid xs={12} md={5}>
            <FormattedNumberInput
              label="€ Life Cover Amount"
              formControl={formControl}
              prefix="€"
              inputName="lifeCoverAmount"
              errors={errors}
              setValue={setValue}
            />
          </Grid>
        </>
      )}
      {seriousIllness && seriousIllness !== NONE && (
        <Grid xs={12} md={5}>
          <FormattedNumberInput
            label="€ Serious illness amount"
            prefix="€"
            formControl={formControl}
            inputName="seriousIllnessAmount"
            errors={errors}
            setValue={setValue}
          />
        </Grid>
      )}
      {seriousIllness && (
        <>
          <Grid xs={12} md={5}>
            <FormattedNumberInput
              label="€ Premium Amount"
              formControl={formControl}
              prefix="€"
              inputName="premiumAmount"
              errors={errors}
              setValue={setValue}
            />
          </Grid>
          <Clients
            showLifeOfAnotherInput={basis === LIFE_OF_ANOTHER}
            errors={errors}
            formControl={formControl}
            watch={watch}
            setValue={setValue}
          />
        </>
      )}
    </>
  );
};

TermProtectionInsurance.propTypes = {
  formControl: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
};
