export const populateArray = ({ regularPremiumResult = {} }) => [
  {
    label: 'Initial Commission:',
    value: regularPremiumResult.initialCommission,
    key: 'initialCommission',
  },
  {
    label: 'Renewal:',
    value: regularPremiumResult.renewalCommission,
    key: 'renewal',
  },
  {
    label: 'Net Allocations:',
    value: regularPremiumResult.netAllocation,
    key: 'netAllocations',
  },
  {
    label: 'Trail:',
    value: regularPremiumResult.trail,
    key: 'trail',
  },
  {
    label: 'Total AMC incl trail:',
    value: regularPremiumResult.totalAMC,
    key: 'totalAMC',
  },
  {
    label: 'Exit Charges:',
    value: regularPremiumResult.exitCharges,
    key: 'exitCharges',
  },
  {
    label: 'Fund switches:',
    value: regularPremiumResult.fundSwitches,
    key: 'fundSwitches',
  },
  {
    label: 'Commission Code:',
    value: regularPremiumResult.commissionCode,
    key: 'commissionCode',
  },
  {
    label: 'Additional AMC for Multi Asset Fund:',
    value: regularPremiumResult.additionalAMC,
    key: 'additionalAMC',
  },
];
