export const DEFAULT_INSURANCE_VALUES = {
  term: 10,
  basis: null,
  NRA: 65,
  numberOfDependants: 3,
  client1MaritalStatus: null,
  client2MaritalStatus: null,
  client2Smoker: 'Non-Smoker',
  client1Smoker: 'Non-Smoker',
  companyName: null,
  protection: null,
  seriousIllness: '',
  insuranceType: null,
  lifeCoverAmount: null,
  premiumAmount: null,
  benefitAmount: null,
  inheritanceTax: null,
  seriousIllnessAmount: null,
  client2FirstName: null,
  client2LastName: null,
  client1FirstName: null,
  client1LastName: null,
  clientCompanyName: null,
  productType: null,
  financialAdvisor: '',
};
