import { AlignmentType, LevelFormat, UnderlineType } from 'docx';

export const styles = {
  default: {
    heading1: {
      run: {
        color: '000000',
        font: {
          size: 10,
          name: 'Ariel',
        },
      },
      paragraph: {
        spacing: {
          after: 120,
        },
        color: '000000',
      },
    },
    heading2: {
      run: {
        size: 26,
        bold: true,
        underline: {
          type: UnderlineType.DOUBLE,
          color: 'FF0000',
        },
      },
    },
    paragraph: {
      size: 10,
      font: {
        name: 'Arial',
      },
      run: {
        size: 10,
        font: {
          name: 'Arial',
        },
      },
    },
    listParagraph: {
      run: {
        color: '000000',
        size: 20,
        font: {
          name: 'Arial',
        },
      },
    },
  },
  paragraphStyles: [
    {
      id: 'aside',
      name: 'Aside',
      basedOn: 'Normal',
      next: 'Normal',
      run: {
        color: '1f1919',
        italics: true,
      },
    },
  ],
};

export const numbering = {
  config: [
    {
      reference: 'my-crazy-numbering',
      levels: [
        {
          level: 0,
          format: LevelFormat.LOWER_LETTER,
          text: '%1)',
          alignment: AlignmentType.LEFT,
        },
      ],
    },
  ],
};
