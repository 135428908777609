export const PREMIUM_TYPES = {
  SINGLE_PREMIUM: 'Single Premium',
  REGULAR_PREMIUM: 'Regular Premium',
  BOTH: 'Both',
};

export const COVER_TYPES = {
  PENSION: 'Pension',
  SAVINGS_INVESTMENTS: 'Savings&Investments',
  PROTECTION: 'Protection',
};

export const PRODUCT_TYPES = {
  ARF: 'ARF',
};
