import { AlignmentType, Footer } from 'docx';
import { paragraphWithTextRun, textRun } from '../../wordDoc.utils';
import {
  FLANNELLY_FOOTER,
  HARDIMAN_FOOTER,
  HASTINGS_FINANCIAL_PENSIONS_FOOTER,
  HASTINGS_FOOTER,
} from '../../Insurance/data/insuranceDocument';
import {
  DAVID_FLANNELLY,
  HASTINGS,
  HASTINGS_LIFE_PENSIONS,
  PAT_HARDIMAN,
} from '../../../../constants/form';

const FOOTER_TEXT_SIZE = 16;

const generateFooter = ({ hastingsCompany }) => {
  if (hastingsCompany === HASTINGS) {
    return HASTINGS_FOOTER;
  }
  if (hastingsCompany === DAVID_FLANNELLY) {
    return FLANNELLY_FOOTER;
  }
  if (hastingsCompany === HASTINGS_LIFE_PENSIONS) {
    return HASTINGS_FINANCIAL_PENSIONS_FOOTER;
  }
  if (hastingsCompany === PAT_HARDIMAN) {
    return HARDIMAN_FOOTER;
  }
  return null;
};

export const HastingsFooter = ({ hastingsCompany }) => ({
  default: new Footer({
    children: [
      paragraphWithTextRun({
        children: [
          textRun({
            text: generateFooter({
              hastingsCompany,
            }),
            size: FOOTER_TEXT_SIZE,
            alignment: AlignmentType.CENTER,
          }),
        ],
        alignment: AlignmentType.CENTER,
      }),
    ],
  }),
});
