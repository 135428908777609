import { removeSpace } from '../../../../../utils/utils';

const getRiskProfile = {
  VeryLow:
    "You are a 'Very low risk' investor. This means that you are not willing to accept significant risks with your money accepting the prospect of low returns to achieve this.",
  Low: "You are a 'Low risk' investor. You are likely to accept limited risks with your money and you will want to try to avoid large fluctuations in the value of your investment,accepting the prospect of more modest returns to achieve this.",
  LowtoMedium:
    "You are a 'Low to medium risk' investor. You are likely to accept some risk in return for the potential of higher investment gains over the long-term.You want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the short term.",
  Medium:
    "You are a 'Medium risk' investor. You are likely to accept significant risk in return for the potential of good investment gains over the long-term. You accept there will be significant fluctuations in the value of your investment, particularly over the short-term. However, you will want to limit the amount of your money held in more risky investments.",
  MediumtoHigh:
    "You are a 'Medium to high risk' investor. You are likely to understand that the value of your investment can go down and up sharply with the potential for greater returns over the long-term.",
  High: "You are a 'High risk' investor. You are likely to aim for high possible returns and accept higher levels of risk, recognising that the value of your investment may fluctuate very sharply, particularly over the short-term.",
  VeryHigh:
    "You are a 'Very high risk' investor. You are likely to aim for the highest possible returns and accept the highest levels of risk, recognising that the value of your investment may fluctuate very widely, particularly over the short-term.",
};

export const riskProfileMap = {
  VeryLow: 1,
  Low: 2,
  LowtoMedium: 3,
  Medium: 4,
  MediumtoHigh: 5,
  High: 6,
  VeryHigh: 7,
};

export const RECOMMENDED_PRODUCT = [
  {
    text: 'I have examined your financial needs, and I believe you should take out an Retail Master Trust plan.',
    level: 0,
  },
  {
    text: 'I have made you aware of the gross and net cost of contributing to the plan and the long-term nature of this plan.',
    level: 0,
  },
  {
    text: 'An Retail Master Trust plan is the most suitable product as it can meet your contribution needs and it is flexible in terms of fund range and charging structures.',
    level: 0,
  },
  {
    text: 'The returns on this plan are not guaranteed but over the long term may produce a return in excess of deposits.',
    level: 0,
  },
  {
    text: 'This retirement plan allows you increase or reduce your contribution level at any time/allows you to make additional single contributions.',
    level: 0,
  },
  {
    text: 'This plan is designed as a long-term retirement product and generally you will not have access to this fund until your selected Normal Retirement Age.',
    level: 0,
  },
  {
    text: 'This plan is in line with your personal circumstances and suits your requirements. We recommend that this retirement plan be reviewed at least every year.',
    level: 0,
  },
];

export const attitudeToRisk = ({ riskProfile }) => [
  {
    text: `Based on the answers to the risk profile questionnaire which you completed, your risk profile rating is a ${
      riskProfileMap[removeSpace(riskProfile)]
    } (${riskProfile} risk). We use the ESMA risk rating which categorizes risk on a scale of 1 (Very low risk) to 7 (Very high risk)`,
    level: 0,
  },
  {
    text: getRiskProfile[removeSpace(riskProfile)],
    level: 0,
  },
  {
    text: `We recommend you invest in a ${riskProfile} risk fund. In particular the following portfolio:`,
    level: 0,
  },
];

export const defaultDynamicStatements = ({ riskProfile }) => [
  `This portfolio has an ESMA risk rating of ${
    riskProfileMap[removeSpace(riskProfile)]
  }, which is in line with your risk profile.`,
];
