/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getHastingsToolkit = /* GraphQL */ `
  query GetHastingsToolkit($id: ID!) {
    getHastingsToolkit(id: $id) {
      id
      name
    }
  }
`;
export const listHastingsToolkits = /* GraphQL */ `
  query ListHastingsToolkits(
    $filter: TableHastingsToolkitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHastingsToolkits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const getCommissionStructure = /* GraphQL */ `
  query GetCommissionStructure($id: ID!) {
    getCommissionStructure(id: $id) {
      id
      name
      label
      value
      dropdown
      step
      insuranceProvider
      minAge
      maxAge
      minAmount
      maxAmount
      dependencies
      productType
      premiumType
      additionalAMC
      commissionCode
      netAllocation
      trail
      totalAMC
      fundSwitches
      exitCharges
      coverType
      initialCommission
      outsideRangeMessage
      minimumTerm
      renewalCommission
      associatedProducts
      renewal
      initialYear1
      initialYear2
      initialYear3
      initialYear4
      initialYear5
    }
  }
`;
export const listCommissionStructures = /* GraphQL */ `
  query ListCommissionStructures(
    $filter: TableCommissionStructureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommissionStructures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        label
        value
        dropdown
        step
        insuranceProvider
        minAge
        maxAge
        minAmount
        maxAmount
        dependencies
        productType
        premiumType
        additionalAMC
        commissionCode
        netAllocation
        trail
        totalAMC
        fundSwitches
        exitCharges
        coverType
        initialCommission
        outsideRangeMessage
        minimumTerm
        renewalCommission
        associatedProducts
        renewal
        initialYear1
        initialYear2
        initialYear3
        initialYear4
        initialYear5
      }
      nextToken
    }
  }
`;
export const queryCommissionStructuresByStep = /* GraphQL */ `
  query QueryCommissionStructuresByStep(
    $step: Int!
    $first: Int
    $after: String
  ) {
    queryCommissionStructuresByStep(step: $step, first: $first, after: $after) {
      items {
        id
        name
        label
        value
        dropdown
        step
        insuranceProvider
        minAge
        maxAge
        minAmount
        maxAmount
        dependencies
        productType
        premiumType
        additionalAMC
        commissionCode
        netAllocation
        trail
        totalAMC
        fundSwitches
        exitCharges
        coverType
        initialCommission
        outsideRangeMessage
        minimumTerm
        renewalCommission
        associatedProducts
        renewal
        initialYear1
        initialYear2
        initialYear3
        initialYear4
        initialYear5
      }
      nextToken
    }
  }
`;
export const queryCommissionStructuresByDropdown = /* GraphQL */ `
  query QueryCommissionStructuresByDropdown(
    $dropdown: String!
    $first: Int
    $after: String
  ) {
    queryCommissionStructuresByDropdown(
      dropdown: $dropdown
      first: $first
      after: $after
    ) {
      items {
        id
        name
        label
        value
        dropdown
        step
        insuranceProvider
        minAge
        maxAge
        minAmount
        maxAmount
        dependencies
        productType
        premiumType
        additionalAMC
        commissionCode
        netAllocation
        trail
        totalAMC
        fundSwitches
        exitCharges
        coverType
        initialCommission
        outsideRangeMessage
        minimumTerm
        renewalCommission
        associatedProducts
        renewal
        initialYear1
        initialYear2
        initialYear3
        initialYear4
        initialYear5
      }
      nextToken
    }
  }
`;
export const queryCommissionStructuresByName = /* GraphQL */ `
  query QueryCommissionStructuresByName(
    $name: String!
    $first: Int
    $after: String
  ) {
    queryCommissionStructuresByName(name: $name, first: $first, after: $after) {
      items {
        id
        name
        label
        value
        dropdown
        step
        insuranceProvider
        minAge
        maxAge
        minAmount
        maxAmount
        dependencies
        productType
        premiumType
        additionalAMC
        commissionCode
        netAllocation
        trail
        totalAMC
        fundSwitches
        exitCharges
        coverType
        initialCommission
        outsideRangeMessage
        minimumTerm
        renewalCommission
        associatedProducts
        renewal
        initialYear1
        initialYear2
        initialYear3
        initialYear4
        initialYear5
      }
      nextToken
    }
  }
`;
export const getStatementOfSuitabilityPensions = /* GraphQL */ `
  query GetStatementOfSuitabilityPensions($id: ID!) {
    getStatementOfSuitabilityPensions(id: $id) {
      id
      insuranceProvider
      type
      riskProfile
      statements
      ESG
    }
  }
`;
export const listStatementOfSuitabilityPensions = /* GraphQL */ `
  query ListStatementOfSuitabilityPensions(
    $filter: TableStatementOfSuitabilityPensionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatementOfSuitabilityPensions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        insuranceProvider
        type
        riskProfile
        statements
        ESG
      }
      nextToken
    }
  }
`;
