import React from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from '@material-ui/lab';
import { findSavingsInvestmentResult } from '../step4.utils';
import { PREMIUM_TYPES } from '../../../CommissionStructure.constant';
import { convertStringCurrencyToNumber } from '../../../../../utils/utils';
import { SavingsSinglePremiumContent } from './SavingsSinglePremiumContent/SavingsSinglePremiumContent';
import { SavingsRegularPremiumContent } from './SavingsRegularPremiumContent/SavingsRegularPremiumContent';

export const SavingsInvestments = ({
  commissionStructures,
  singlePremium,
  regularPremium,
  insuranceProvider,
  productType,
  coverType,
}) => {
  const convertedSinglePremium = convertStringCurrencyToNumber(singlePremium);
  const convertedRegularPremium = convertStringCurrencyToNumber(regularPremium);

  const singlePremiumResult = findSavingsInvestmentResult({
    commissionStructures,
    premiumType: PREMIUM_TYPES.SINGLE_PREMIUM,
    premium: convertedSinglePremium,
    insuranceProvider,
    productType,
    coverType,
  });

  const regularPremiumResult = findSavingsInvestmentResult({
    commissionStructures,
    premiumType: PREMIUM_TYPES.REGULAR_PREMIUM,
    premium: convertedRegularPremium,
    insuranceProvider,
    productType,
    coverType,
  });

  return (
    <>
      <TabPanel value={1}>
        <SavingsSinglePremiumContent
          convertedSinglePremium={convertedSinglePremium}
          singlePremiumResult={singlePremiumResult}
        />
      </TabPanel>
      <TabPanel value={2}>
        <SavingsRegularPremiumContent
          convertedRegularPremium={convertedRegularPremium}
          regularPremiumResult={regularPremiumResult}
        />
      </TabPanel>
    </>
  );
};

SavingsInvestments.propTypes = {
  commissionStructures: PropTypes.string,
  singlePremium: PropTypes.string,
  regularPremium: PropTypes.string,
  insuranceProvider: PropTypes.string,
  productType: PropTypes.string,
  coverType: PropTypes.string,
};
