import React, { useState, Children } from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { PreviousButton } from './PreviousButton/PreviousButton';
import { NextButton } from './NextButton/NextButton';
import { CompleteButton } from './CompleteButton/CompleteButton';

const useStyles = makeStyles(() =>
  createStyles({
    buttons: {
      paddingTop: '32px',
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    stepContainer: {
      flexGrow: 1,
    },
  })
);

export const Wizard = ({ children, disabled }) => {
  const classes = useStyles();
  const [activePageIndex, setActivePageIndex] = useState(0);

  const pages = Children.toArray(children);
  const currentPage = pages[activePageIndex];

  const nextStep = () => {
    setActivePageIndex((index) => index + 1);
  };

  const previousStep = () => {
    setActivePageIndex((index) => index - 1);
  };

  return (
    <Container className={classes.container}>
      <div className={classes.stepContainer}>{currentPage}</div>
      <div className={classes.buttons}>
        <PreviousButton
          activePageIndex={activePageIndex}
          onPrevClick={previousStep}
        />
        <NextButton
          activePageIndex={activePageIndex}
          pages={pages}
          onNextClick={nextStep}
        />
        <CompleteButton
          onNextClick={nextStep}
          pages={pages}
          activePageIndex={activePageIndex}
          disabled={disabled}
        />
      </div>
    </Container>
  );
};

Wizard.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};
