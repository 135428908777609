import {
  DUAL,
  JOINT,
  LIFE_OF_ANOTHER,
  MORTGAGE_PROTECTION,
  NONE,
  SINGLE,
} from '../../../../constants/form';

export const termProtectionRecommendedProduct = ({
  lifeCoverAmount,
  seriousIllness,
  seriousIllnessAmount,
  term,
  premiumAmount,
  basis,
  client1MaritalStatus,
  client2MaritalStatus,
  client2FirstName,
  insuranceType,
}) => {
  const youOrBoth = client2FirstName ? 'both of you' : 'you';
  const showLife = basis === LIFE_OF_ANOTHER ? '' : ' life';

  const recommendedProducts = [
    {
      text: `I have examined your financial needs, and I believe you should take out a guaranteed ${insuranceType} policy on a ${basis}${showLife} basis for at least ${term} years.`,
      level: 0,
    },
    {
      text: `A life cover sum insured of ${lifeCoverAmount} is suitable for ${youOrBoth}.`,
      level: 0,
    },
    {
      text: `The monthly premium required is ${premiumAmount} inclusive of the 1% government levy.`,
      level: 0,
    },
    {
      text:
        'In terms of affordability, there is a sufficient amount of capital available, as per our fact find, to\n' +
        'pay for this.',
      level: 0,
    },
    {
      text:
        'This plan is in line with your personal circumstances and suits your requirements. I recommend\n' +
        'that your circumstances are reviewed every year to ensure your needs and objectives are still being catered for.',
      level: 0,
    },
    {
      text: 'The premium will remain fixed for the term of the plan and therefore will not increase.',
      level: 0,
    },
  ];

  if (insuranceType === MORTGAGE_PROTECTION) {
    recommendedProducts.push({
      text:
        'The policy is designed to pay off your outstanding mortgage should you die during the term of\n' +
        'your mortgage, provided that interest rates do not exceed 6% per annum during the term of the\n' +
        'loan. The policy then ceases.',
      level: 0,
    });
  }

  if (basis === LIFE_OF_ANOTHER) {
    recommendedProducts.push({
      text: 'Due to the potential inheritance tax liability you wish to take this policy out on a life of another basis',
      level: 0,
    });
  }

  if (seriousIllness !== NONE) {
    recommendedProducts.splice(2, 0, {
      text: `A serious illness cover sum insured of ${seriousIllnessAmount} is suitable for ${youOrBoth}.`,
      level: 0,
    });
  }

  if (
    client1MaritalStatus &&
    client2MaritalStatus === SINGLE &&
    (basis === DUAL || basis === JOINT)
  ) {
    recommendedProducts.push({
      text: 'There may be an inheritance tax liability on any potential claims made on this plan as you are unmarried. You are happy to proceed on this basis.',
      level: 0,
    });
  }

  return recommendedProducts;
};

export const wholeOfLifeRecommendedProduct = ({
  basis,
  client2FirstName,
  inheritanceTax,
  premiumAmount,
  lifeCoverAmount,
}) => {
  const youOrBoth = client2FirstName ? 'both of you' : 'you';

  const recommendedProducts = [
    {
      text: `I have examined your financial needs, and I believe you should take out a Whole of Life policy on a ${basis} basis.`,
      level: 0,
    },
    {
      text: `A life cover sum insured of ${lifeCoverAmount} is suitable for ${youOrBoth}.`,
      level: 0,
    },
    {
      text: `The monthly premium required is ${premiumAmount} inclusive of the 1% government levy.`,
      level: 0,
    },
    {
      text: 'This plan is in line with your personal circumstances and suits your requirements. I recommend that your circumstances are reviewed every year to ensure your needs and objectives are still being catered for.',
      level: 0,
    },
    {
      text: 'The premium will remain fixed for the term of the plan and therefore will not increase.',
      level: 0,
    },
  ];

  if (inheritanceTax) {
    recommendedProducts.push({
      text:
        'Please note that if this policy is being used for Inheritance Tax planning, it is strongly recommended that you complete a Section 72 Trust Form or provide for this policy in their Will. Otherwise the policy proceeds will not qualify for relief under Section 72 of the Capital\n' +
        'Acquisitions Tax Consolidation Act 2003. I have advised that you seek independent advice on this matter.',
      level: 0,
    });
  }

  return recommendedProducts;
};

export const incomeProtectionRecommendedProducts = ({
  protection,
  NRA = 10,
  benefitAmount = 0,
  premiumAmount = 0,
}) => [
  {
    text: `I have examined your financial needs, and I believe you should take out an ${protection.toLowerCase()} policy to age ${NRA}.`,
    level: 0,
  },
  {
    text: `An income protection benefit amount of ${benefitAmount} is suitable.`,
    level: 0,
  },
  {
    text: `The monthly premium required is ${premiumAmount} inclusive of the 1% government levy.`,
    level: 0,
  },
  {
    text: 'In terms of affordability, there is a sufficient amount of capital available, as per our fact find, to pay for this.',
    level: 0,
  },
  {
    text: 'This plan is in line with your personal circumstances and suits your requirements. I recommend that your circumstances are reviewed every year to ensure your needs and objectives are still being catered for.',
    level: 0,
  },
  {
    text: 'The premium will remain fixed for the term of the plan and therefore will not increase.',
    level: 0,
  },
];
