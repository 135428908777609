import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Zoom } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: '10px',
      paddingTop: '4px',
      color: '#1e377d',
    },
  })
);
export const FormLabel = ({ label, playTransition = true, styles }) => {
  const classes = useStyles();
  if (!playTransition) {
    return (
      <InputLabel style={{ ...styles }} className={classes.label}>
        {label}
      </InputLabel>
    );
  }

  return (
    <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
      <InputLabel style={{ ...styles }} className={classes.label}>
        {label}
      </InputLabel>
    </Zoom>
  );
};

FormLabel.propTypes = {
  label: PropTypes.string,
  playTransition: PropTypes.bool,
  styles: PropTypes.object,
};
