import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Zoom } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      fontSize: '10px',
      paddingTop: '8px',
      color: 'red',
    },
  })
);

export const ErrorMessage = ({ message, playTransition = true }) => {
  const classes = useStyles();
  return (
    <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
      <span className={classes.error}>{message}</span>
    </Zoom>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  playTransition: PropTypes.bool,
};
