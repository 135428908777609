// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const APP_SYNC_CONFIG = {
  aws_appsync_graphqlEndpoint:
    'https://zmffx7nbxzhgfmbgpw7r4n6zgm.appsync-api.eu-west-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-3qznzghdpjffbbnzx55v54yhru',
};

export default APP_SYNC_CONFIG;
