import React, { useState } from 'react';
import { Container, Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';

import { UserInput } from '../../components/UserInput/UserInput';
import Theme from '../../constants/theme';
import useAccount from '../../providers/AccountProvider/useAccount';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: Theme.spacing(5),
      paddingBottom: Theme.spacing(5),
    },
    paper: {
      padding: Theme.spacing(5),
      justifyContent: 'center',
    },
    button: {
      marginTop: Theme.spacing(3),
      width: '100%',
    },
  })
);

export const ChangePassword = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm({});

  const { authenticate } = useAccount();

  const onSubmit = (data) => {
    setLoading(true);
    authenticate({
      Username: data.username,
      Password: data.password,
      NewPassword: data.newPassword,
    })
      .then((res) => {
        console.log('Logged in!', res);
      })
      .catch((err) => {
        console.error('Failed to login', err);
      });
    setLoading(false);
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper variant="outlined" className={classes.paper} elevation={3}>
        <Grid alignItems="center" justifyContent="center" spacing={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              className={classes.grid}
              style={{ gap: 15 }}
            >
              <Typography gutterBottom variant="h5" component="h2">
                Change your password.
              </Typography>
              <UserInput
                formControl={control}
                inputName="username"
                placeholder="Username"
              />
              <PasswordInput
                formControl={control}
                inputName="password"
                placeholder="Old Password *"
                type="password"
              />
              <PasswordInput
                formControl={control}
                inputName="newPassword"
                placeholder="New Password *"
                type="password"
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={loading}
                className={classes.button}
              >
                {loading && (
                  <CircularProgress size={20} style={{ marginRight: 20 }} />
                )}
                Change Password
              </Button>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Container>
  );
};
