import { AlignmentType, Table, TableCell, TableRow } from 'docx';
import { boldTextRun, paragraphWithTextRun } from '../../wordDoc.utils';

export const warnings = () =>
  new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              paragraphWithTextRun({
                spacingAfter: 0.2,
                spacingBefore: 0.1,
                children: [
                  boldTextRun({
                    alignment: AlignmentType.CENTER,
                    text: 'Warning: If you invest in this product you may lose some or all of the money you invest.',
                    lineBreak: 1,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              paragraphWithTextRun({
                spacingAfter: 0.2,
                spacingBefore: 0.1,
                children: [
                  boldTextRun({
                    text: 'Warning: The value of your investment may go down as well as up.',
                    lineBreak: 1,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              paragraphWithTextRun({
                spacingAfter: 0.2,
                spacingBefore: 0.1,
                children: [
                  boldTextRun({
                    text: 'Warning: Past performance is not a reliable guide to future performance.',
                    lineBreak: 1,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              paragraphWithTextRun({
                spacingAfter: 0.2,
                spacingBefore: 0.1,
                children: [
                  boldTextRun({
                    text: 'Warning: Past performance is not a reliable guide to future performance.',
                    lineBreak: 1,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              paragraphWithTextRun({
                spacingAfter: 0.2,
                spacingBefore: 0.1,
                children: [
                  boldTextRun({
                    text: 'Warning: this product may be affected by changes in currency exchange rates.',
                    lineBreak: 1,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  });
