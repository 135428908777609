import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, Input, InputAdornment, Zoom } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '100%',
    },
  })
);

export const PasswordInput = ({
  inputName,
  formControl,
  placeholder,
  inputStyle,
  autoComplete = 'off',
  playTransition = true,
  disabled = false,
  required = true,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();
  return (
    <Controller
      name={inputName}
      control={formControl}
      defaultValue=""
      render={({ field }) => (
        <Zoom in={playTransition} style={{ transitionDelay: '100ms' }}>
          <Input
            disabled={disabled}
            autoComplete={autoComplete}
            label={placeholder}
            placeholder={placeholder}
            required={required}
            {...field}
            className={classes.input}
            style={inputStyle}
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Zoom>
      )}
    />
  );
};

PasswordInput.propTypes = {
  inputName: PropTypes.string,
  formControl: PropTypes.object,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.object,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  playTransition: PropTypes.bool,
  required: PropTypes.bool,
};
