import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { FormattedNumberInput } from '../../../../components/FormattedNumberInput/FormattedNumberInput';
import { DatePicker } from '../../../../components/DatePicker/DatePicker';
import { FormLabel } from '../../../../components/FormLabel/FormLabel';
import { NumberSlider } from '../../../../components/NumberSlider/NumberSlider';
import { COVER_TYPES, PREMIUM_TYPES } from '../../CommissionStructure.constant';
import {
  COVER_TYPE,
  PREMIUM_TYPE,
  PRODUCT_TYPE,
} from '../../../../constants/form';

export const Step3 = ({ control, errors = {}, setValue, watch }) => {
  const premiumType = watch(PREMIUM_TYPE);
  const coverType = watch(COVER_TYPE);
  const productType = watch(PRODUCT_TYPE);

  const onRetirementAgeChange = (value) => {
    setValue('pensionAge', value);
  };

  return (
    <Grid container xs={12} style={{ gap: 15 }} spacing={20}>
      {coverType !== COVER_TYPES.SAVINGS_INVESTMENTS &&
        productType !== 'ARF' && (
          <DatePicker
            playTransition={false}
            formControl={control}
            selectFieldName="Date of Birth"
            datePickerName="dateOfBirth"
          />
        )}
      {premiumType !== PREMIUM_TYPES.SINGLE_PREMIUM && (
        <FormattedNumberInput
          label="€ Regular Premium Amount"
          formControl={control}
          inputName="regularPremium"
          errors={errors}
          setValue={setValue}
          prefix="€"
          defaultValue=""
          playTransition={false}
        />
      )}
      {premiumType !== PREMIUM_TYPES.REGULAR_PREMIUM && (
        <FormattedNumberInput
          label="€ Single Premium Amount"
          formControl={control}
          inputName="singlePremium"
          errors={errors}
          prefix="€"
          setValue={setValue}
          defaultValue=""
          playTransition={false}
        />
      )}
      {coverType === COVER_TYPES.PENSION && productType !== 'ARF' && (
        <>
          <FormLabel label="Normal Retirement Age" playTransition={false} />
          <NumberSlider
            formControl={control}
            min={60}
            max={70}
            defaultValue={65}
            sliderName="retirementAge"
            valuetext="Normal Retire Age"
            onChange={onRetirementAgeChange}
            errors={errors}
            playTransition={false}
          />
        </>
      )}
    </Grid>
  );
};

Step3.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};
