import {
  AlignmentType,
  convertInchesToTwip,
  Table,
  TableCell,
  TableRow,
} from 'docx';
import { paragraphWithTextRun, textRun } from '../../wordDoc.utils';
import { header, notice } from '../../Insurance/data/insuranceDocument';

export const HeaderMessage = new Table({
  rows: [
    new TableRow({
      children: [
        new TableCell({
          children: [
            paragraphWithTextRun({
              alignment: AlignmentType.CENTER,
              children: [
                textRun({
                  text: notice,
                }),
              ],
            }),
            paragraphWithTextRun({
              alignment: AlignmentType.CENTER,
              children: [
                textRun({
                  text: header,
                }),
              ],
            }),
          ],
          margins: {
            top: convertInchesToTwip(0.05),
            bottom: convertInchesToTwip(0.05),
            left: convertInchesToTwip(0.1),
            right: convertInchesToTwip(0.1),
          },
        }),
      ],
    }),
  ],
  margins: {
    top: convertInchesToTwip(1),
  },
  alignment: AlignmentType.CENTER,
});
