import {
  AlignmentType,
  BorderStyle,
  convertInchesToTwip,
  Paragraph,
  TableCell,
  TextRun,
  WidthType,
} from 'docx';

export const textRun = ({
  text,
  font = 'Arial',
  size = 20,
  lineBreak = 0,
  bold = false,
  alignment = AlignmentType.JUSTIFIED,
  color = 'black',
}) =>
  new TextRun({
    text,
    bold,
    font,
    color,
    size,
    break: lineBreak,
    alignment,
  });

export const boldTextRun = ({
  text,
  font = 'Arial',
  size = 20,
  color = 'Black',
  lineBreak,
  alignment = AlignmentType.JUSTIFIED,
}) =>
  new TextRun({
    text,
    bold: true,
    font,
    size,
    color,
    break: lineBreak,
    alignment,
  });

export const paragraph = ({
  text = '',
  font = 'Arial',
  size = 20,
  alignment = AlignmentType.JUSTIFIED,
}) =>
  new Paragraph({
    text,
    font,
    size,
    alignment,
  });

export const paragraphWithBullet = ({
  text,
  level = 0,
  font = 'Arial',
  size = 20,
  alignment = AlignmentType.JUSTIFIED,
}) =>
  new Paragraph({
    style: 'listParagraph',
    text,
    alignment,
    bullet: {
      level,
    },
    listItems: {
      font,
      size,
    },
  });

export const paragraphWithTextRun = ({
  children,
  font = 'Arial',
  size = 20,
  alignment = AlignmentType.JUSTIFIED,
  spacingBefore = 0,
  spacingAfter = 0,
  indentLeft = 0,
}) =>
  new Paragraph({
    children,
    font,
    size,
    alignment,
    spacing: {
      before: convertInchesToTwip(spacingBefore),
      after: convertInchesToTwip(spacingAfter),
    },
    indent: {
      left: indentLeft,
    },
  });

export const generateBulletPoints = ({ bulletArray }) =>
  bulletArray.map((bulletPoint) =>
    paragraphWithBullet({
      text: bulletPoint.text,
      level: bulletPoint.level,
    })
  );

export const tableCell = ({
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  alignment = AlignmentType.LEFT,
  children,
  width = {
    size: 100,
    type: WidthType.PERCENTAGE,
  },
  columnSpan = 1,
}) =>
  new TableCell({
    children: [
      paragraphWithTextRun({
        alignment,
        children,
      }),
    ],
    margins: {
      right: convertInchesToTwip(marginRight),
      left: convertInchesToTwip(marginLeft),
      top: convertInchesToTwip(marginTop),
      bottom: convertInchesToTwip(marginBottom),
    },
    columnSpan,
    width,
    borders: {
      top: {
        style: BorderStyle.NONE,
        size: 1,
      },
      bottom: {
        style: BorderStyle.NONE,
        size: 1,
      },
      left: {
        style: BorderStyle.NONE,
        size: 1,
      },
      right: {
        style: BorderStyle.NONE,
        size: 1,
      },
    },
  });
