import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import { Controller } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Zoom } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '100%',
    },
  })
);

export const UserInput = ({
  inputName,
  formControl,
  placeholder,
  inputStyle,
  autoComplete = 'off',
  playTransition = true,
  type = 'text',
  disabled = false,
}) => {
  const classes = useStyles();

  if (!playTransition) {
    return (
      <Controller
        name={inputName}
        control={formControl}
        defaultValue=""
        render={({ field }) => (
          <TextField
            label={placeholder}
            {...field}
            className={classes.input}
            style={inputStyle}
            required
            placeholder={placeholder}
            inputProps={{ autocomplete: autoComplete }}
            type={type}
            disabled={disabled}
          />
        )}
      />
    );
  }

  return (
    <Controller
      name={inputName}
      control={formControl}
      defaultValue=""
      render={({ field }) => (
        <Zoom in style={{ transitionDelay: '100ms' }}>
          <TextField
            label={placeholder}
            {...field}
            className={classes.input}
            style={inputStyle}
            required
            placeholder={placeholder}
            inputProps={{ autocomplete: autoComplete }}
            type={type}
            disabled={disabled}
          />
        </Zoom>
      )}
    />
  );
};

UserInput.propTypes = {
  inputName: PropTypes.string,
  formControl: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  inputStyle: PropTypes.object,
  autoComplete: PropTypes.string,
  playTransition: PropTypes.bool,
  disabled: PropTypes.bool,
};
