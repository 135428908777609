import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Grid, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import gql from 'graphql-tag';
import { DEFAULT_INSURANCE_VALUES } from '../StatementOfSuitability/LifeInsurance/LifeInsurance.constant';
import Theme from '../../constants/theme';
import { Step1 } from './Steps/Step1/Step1';
import { Step2 } from './Steps/Step2/Step2';
import { Wizard } from '../../components/Wizard/Wizard';
import { GraphQLClient } from '../../graphql/GraphQLClient';
import { listCommissionStructures } from '../../graphql/queries';
import { Step3 } from './Steps/Step3/Step3';
import { Step4 } from './Steps/Step4/Step4';
import { COVER_TYPES } from './CommissionStructure.constant';
import {
  COVER_TYPE,
  INSURANCE_PROVIDERS,
  PREMIUM_TYPE,
  PRODUCT_TYPE,
} from '../../constants/form';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: Theme.spacing(5),
      paddingBottom: Theme.spacing(5),
    },
    title: {
      textAlign: 'center;',
      paddingBottom: '16px',
      color: Theme.palette.primary.main,
    },
    paper: {
      padding: '32px',
    },
  })
);

export const CommissionStructures = () => {
  const [commissionStructures, setCommissionStructures] = useState([]);

  useEffect(() => {
    GraphQLClient.query({
      query: gql(listCommissionStructures),
      variables: { limit: 500 },
    })
      .then((response) => {
        setCommissionStructures(response.data.listCommissionStructures.items);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }, []);

  const classes = useStyles();
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      ...DEFAULT_INSURANCE_VALUES,
    },
  });

  const coverType = watch(COVER_TYPE);
  const productType = watch(PRODUCT_TYPE);
  const premiumType = watch(PREMIUM_TYPE);
  const insuranceProviders = watch(INSURANCE_PROVIDERS);

  const showStepThree = coverType !== 'Protection';

  const isValid = () => {
    if (coverType === COVER_TYPES.PROTECTION) {
      return coverType !== undefined && productType !== null;
    }
    if (coverType === COVER_TYPES.PENSION) {
      return (
        coverType !== undefined &&
        productType !== null &&
        premiumType !== undefined &&
        insuranceProviders !== undefined
      );
    }
    return false;
  };

  const onSubmit = () => {};

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper variant="outlined" className={classes.paper} elevation={3}>
        <Typography variant="h5" className={classes.title}>
          Commission Structures
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container xs={12} style={{ gap: 15 }} spacing={20}>
            <Wizard disabled={!isValid}>
              <Step1
                control={control}
                coverType={coverType}
                commissionStructures={commissionStructures}
              />
              <Step2
                control={control}
                watch={watch}
                productType={productType}
                coverType={coverType}
                commissionStructures={commissionStructures}
              />
              {showStepThree && (
                <Step3 setValue={setValue} control={control} watch={watch} />
              )}
              <Step4
                commissionStructures={commissionStructures}
                watch={watch}
                setValue={setValue}
              />
            </Wizard>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};
